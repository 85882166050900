import { useState } from 'react';
import cn from 'classnames';
import { MappingTableRows } from 'new-beginning/components/pages/IntegrationSettings/field_mapping/MappingTableRows';
import { BFMappingTypes, DropDownOptionType } from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { useUpdateCrmMappingFields } from 'new-beginning/hooks/salesforce/useUpdateCrmMappingFields';
import { SalesforceMapping } from 'new-beginning/hooks/useCrmMappingData';

interface FieldMappingTableProps {
	loading: boolean;
	fieldMappings: SalesforceMapping[];
	sourceFieldSet: DropDownOptionType[];
	targetFieldSet: DropDownOptionType[];
	crmObjectType: BFMappingTypes;
	refreshMappingFields: () => void;
}

export const FieldMappingTable = ({
	loading,
	fieldMappings,
	crmObjectType,
	sourceFieldSet = [],
	targetFieldSet = [],
	refreshMappingFields,
}: FieldMappingTableProps) => {
	const [existingFieldEdits, setExistingFieldEdits] = useState<{ [key: number]: SalesforceMapping }>({});
	const [newMappingFields, setNewMappingFields] = useState<SalesforceMapping[]>([]);
	const { updateFieldMappings, loading: saveEditsLoading, requestApiSuccess, requestErrorMessage } = useUpdateCrmMappingFields();

	const saveFieldMappings = (crmObjectType: BFMappingTypes) => {
		const existingEditsFlattened = Object.values(existingFieldEdits).filter((val) => val !== null);
		const fieldsToDelete = Object.entries(existingFieldEdits).reduce((accum, [key, value]) => {
			if (value === null) {
				accum.push(key);
			}
			return accum;
		}, []);
		updateFieldMappings(newMappingFields, existingEditsFlattened, fieldsToDelete)
			.then(() => {
				return refreshMappingFields();
			})
			.then((res) => {
				setExistingFieldEdits({});
				setNewMappingFields([]);
			})
			.catch((err) => {
				console.error('Error Saving Fields: ', err);
			});
	};

	const editExistingMappingField = (index, values: Partial<SalesforceMapping>) => {
		const originalField = fieldMappings[index];
		const mappingFieldId = originalField?.id;
		const fieldWithEdits = { ...originalField, ...existingFieldEdits?.[mappingFieldId] };
		setExistingFieldEdits({
			...existingFieldEdits,
			[mappingFieldId]: {
				...fieldWithEdits,
				...values,
			},
		});
	};

	const deleteExistingMapping = (mappingId) => {
		setExistingFieldEdits({
			...existingFieldEdits,
			[mappingId]: null,
		});
	};

	const addNewMappingField = () =>
		setNewMappingFields([
			...newMappingFields,
			{
				sourceField: {
					name: null,
					value: null,
					field_domain_type: null,
				},
				targetField: {
					name: null,
					field_domain_type: null,
				},
				type: crmObjectType,
				is_deleteable: true,
				allow_overwrite: false,
				is_key_field: false,
				order: newMappingFields.length,
			},
		]);

	const updateNewMappingField = (mappingIdx: number, values: Partial<DropDownOptionType>) => {
		const updatedMappingSet: SalesforceMapping[] = newMappingFields.map((field, idx) =>
			idx == mappingIdx ? { ...field, ...values } : field
		);
		setNewMappingFields(updatedMappingSet);
	};

	const deleteFieldMapping = (key: number | null = null) => {
		// Delete all Fields when key ommitted
		if (key === null) {
			setNewMappingFields([]);
			setExistingFieldEdits({});
		} else {
			setNewMappingFields(newMappingFields.filter((_, idx) => idx !== key));
		}
	};

	const hasActiveEdits = newMappingFields.length > 0 || Object.keys(existingFieldEdits).length > 0;
	const hasIncompleteEdits = newMappingFields.some((value) => value?.targetField?.name === null);

	const hasNonUniqueTargetFields = (): boolean => {
		const getTarget = (val: SalesforceMapping) => val?.targetField?.name;
		const editedIds = new Set(Object.keys(existingFieldEdits));
		const existingEdits = Object.values(existingFieldEdits).map(getTarget) || [];
		const newFields = newMappingFields?.map(getTarget) || [];

		const uniqueEditFields = new Set([...existingEdits, ...newFields]);
		const uniqueExistingFields = new Set(
			fieldMappings?.filter((mapping) => mapping.id && !editedIds.has(mapping.id.toString()))?.map(getTarget) || []
		);

		return [...uniqueEditFields].some((newTargetField) => uniqueExistingFields.has(newTargetField));
	};
	const hasInvalidEdits = hasIncompleteEdits || hasNonUniqueTargetFields();

	return (
		<div>
			<table className={cn('table', 'bf-table', 'salesforce-mapping-table')}>
				<thead>
					<tr>
						<th>Source</th>
						<th />
						<th>Target</th>
						<th className={cn('text-center')}>Overwrite Behavior</th>
						<th />
					</tr>
				</thead>
				<tbody>
					<MappingTableRows
						setMappingField={editExistingMappingField}
						deleteFieldMapping={deleteExistingMapping}
						fieldMappings={fieldMappings?.map((field, idx) => {
							const isEdit = existingFieldEdits.hasOwnProperty(field?.id);
							if (isEdit && existingFieldEdits?.[field?.id] === null) {
								return null;
							}

							return isEdit ? { isEdit: true, ...existingFieldEdits?.[field?.id] } : field;
						})}
						sourceFieldSet={sourceFieldSet}
						targetFieldSet={targetFieldSet}
						loading={loading}
						crmObjectType={crmObjectType}
					/>
					<MappingTableRows
						setMappingField={updateNewMappingField}
						deleteFieldMapping={deleteFieldMapping}
						fieldMappings={newMappingFields}
						sourceFieldSet={sourceFieldSet}
						targetFieldSet={targetFieldSet}
						loading={loading}
						crmObjectType={crmObjectType}
					/>
					{!fieldMappings && !newMappingFields.length && (
						<tr>
							<td className={cn('p-5')} align='center' colSpan={7}>
								{loading ? (
									<div className='text-primary'>
										<span className={cn('spinner-border', 'spinner-border-lg', 'mx-2')} />
									</div>
								) : (
									<h4 className={cn('text-muted')}>No Results</h4>
								)}
							</td>
						</tr>
					)}
				</tbody>
			</table>
			<div className={cn('d-flex', 'justify-content-between', 'pt-2')}>
				<div className={cn('px-2')}>
					<button onClick={() => addNewMappingField()} disabled={loading} className={cn('btn', 'btn-outline-dark')}>
						Add Field Mapping
					</button>
				</div>
				<div className={cn('px-2')}>
					<button
						onClick={() => deleteFieldMapping()}
						disabled={loading || !hasActiveEdits}
						className={cn('btn', 'btn-outline-secondary', 'me-3')}
					>
						Cancel
					</button>
					<button
						onClick={() => saveFieldMappings(crmObjectType)}
						disabled={loading || saveEditsLoading || !hasActiveEdits || hasInvalidEdits}
						className={cn('btn', 'btn-primary')}
					>
						{!saveEditsLoading && <span>Save Changes</span>}
						{saveEditsLoading && (
							<span>
								<span className={cn('spinner-border', 'spinner-border-sm')} />
								<span className={cn('ms-2')}>Saving</span>
							</span>
						)}
					</button>
				</div>
			</div>
		</div>
	);
};
