export enum LocationType {
	CityState, // City and state that was created from brokers/employers/contacts in our DB
	Zip, // Zip codes from r_reference.us_zip_codes
}

export type ZipLocation = {
	locationType: LocationType.Zip;
	searchValue: string;
	city: string;
	state: string;
	stateCode: string;
	zip: string;
	lat: string;
	lon: string;
};

export type CityStateLocation = {
	locationType: LocationType.CityState;
	searchValue: string;
	city: string;
	state: string;
	stateCode: string;
};

export type EntityLocation = ZipLocation | CityStateLocation;

export type LocationSelection = {
	location: EntityLocation;
	radiusMiles?: number;
};
