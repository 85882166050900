
import React from 'react';
import styled from 'styled-components';
import DropDownPopover from 'atoms/filters/drop/DropDownPopover';


const StyledStatusButton = styled.button<{ open: boolean }>`
  height: 40px;
  padding: 0 10px 0 1rem;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 1px solid ${props => (props.open ? '#185adb' : 'transparent')};
  color: #185adb;
  background-color: ${props =>
    props.open ? 'rgba(24, 90, 219, 0.09)' : 'transparent'};
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 17px;

  svg {
    margin-left: 0.25rem;
  }

  border: 1px solid #185adb;
`;

type StatusDropDownProps = {
    disabled?: boolean;
    getButtonEl: (isMenuOpen) => any;
    getPopOverChildren: (closeMenu: () => void) => any[];
};


const StatusDropDown = ({
    disabled = false,
    getButtonEl,
    getPopOverChildren
}: StatusDropDownProps) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const isMenuOpen = Boolean(anchorEl);

  return (
    <div>
        <StyledStatusButton
            disabled={disabled}
            open={isMenuOpen}
            aria-describedby="status-menu"
            onClick={handleProfileMenuOpen}
            >
            <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                }}
            >
                { getButtonEl(isMenuOpen) }
            </div>
        </StyledStatusButton>
        <DropDownPopover 
            anchorEl={anchorEl}
            handleMenuClose={handleMenuClose} 
            children={getPopOverChildren(handleMenuClose)} 
        />
    </div>
  );
};

export default StatusDropDown;
