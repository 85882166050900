import { useMemo } from 'react';

import { License } from '../../VerifiedProducerBadge.types';

const lines = ['Health', 'Life', 'Property', 'Casualty'];

export const useRows = (licenses: License[]): { label: string; checked: boolean; since?: string }[] => {
	const rows = useMemo(() => {
		if (!licenses || licenses.length < 1) return [];
		const rowsMap = new Map<string, { label: string; checked: boolean; since?: string }>([
			['Life', { label: 'Life', checked: false }],
			['Health', { label: 'Health', checked: false }],
			['Property', { label: 'Property', checked: false }],
			['Casualty', { label: 'Casualty', checked: false }],
		]);
		licenses.forEach(({ line, first_active_date }) => {
			lines.forEach((lineName) => {
				if (line?.includes(lineName)) {
					const currentRow = rowsMap.get(lineName);
					rowsMap.set(lineName, {
						label: lineName,
						checked: true,
						since:
							currentRow.since && currentRow.since < first_active_date ? currentRow.since : first_active_date.split('/')[2],
					});
				}
			});
		});

		return lines.map((line) => rowsMap.get(line));
	}, [licenses]);

	return rows;
};
