import { UseMutationOptions, useMutation } from '@tanstack/react-query';
import { useContext } from 'react';

import { WithAuthenticatedContext } from '../context/AuthenticatedContext';
import { ContactType } from 'common.model/src/types/IContact';

type Data = {
	res: {
		data: {
			res: { profile_url: string };
			consumption: Record<'creditsJustUsed' | 'totalCreditsAllowed' | 'totalCreditsRemaining', number>;
		};
	};
};

export const usePurchaseContactMutation = ({
	contactType,
	profileUrl,
	...options
}: {
	contactType: `${ContactType}`;
	profileUrl: string;
} & UseMutationOptions<Data>) => {
	const { httpClient } = useContext(WithAuthenticatedContext);

	const mutation = useMutation<Data>({
		mutationKey: ['purchaseContact', contactType, profileUrl],
		mutationFn: () => {
			return httpClient.post(`${contactType === 'BROKER' ? `brokerContacts` : 'employer'}/getContact`, {
				profile_url: profileUrl,
			});
		},
		...options,
	});

	return mutation;
};
