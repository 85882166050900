import { useParams } from 'react-router-dom';

import { useEmployerSummaryQuery } from 'new-beginning/queries';
import { useMemo } from 'react';
import { FormattingUtils } from 'ui-components';

export const useHeader = () => {
	const { employerId, employer_master_id } = useParams();
	const { isLoading: loading, data } = useEmployerSummaryQuery({ employerId: employerId || employer_master_id });
	const name = useMemo<string | undefined>(() => data?.employer_master_name && FormattingUtils.formatUpperCasing(data.employer_master_name), [data]);

	return { loading, name };
};
