import { useContext } from 'react';
import { useQuery } from '@tanstack/react-query';

import { QueryPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';

export interface EmployeeSummaryDTO {
	employer_city: string;
	employer_master_id: string;
	employer_master_name: string;
	employer_naics_description: string;
	employer_state: string;
	employer_website: string;
	funding_type: string;
	logo_image_s3_path: string;
	primary_broker: string;
	primary_broker_city: string;
	primary_broker_closest_office_city: string;
	primary_broker_closest_office_state: string;
	primary_broker_state: string;
	primary_broker_unique_code: string;
	total_employees: string;
	renewal_date: string;

	//MISSING DATA FROM API
	number_of_contacts: string;
	employer_linkedin_url: string;
	employer_zip_code: string;
	employer_street_address: string;
	employee_sentiment_score: number;
}

export const useEmployerSummaryQuery = ({ employerId }: { employerId: string }) => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { authUser }: UserContextType = useContext(LoggedInUserContext);
	const query = useQuery<EmployeeSummaryDTO>([QueryKey.EmployeeSummary, employerId, authUser.tenantIdOverride], async () => {
		const response = await httpClient.post(QueryPath.EmployeeSummary, { employer_master_id: employerId });
		if (response.data.res.success) return response.data.res.data;
		throw new Error(response.data.res.data);
	});

	return query;
};
