import cn from 'classnames';

interface SalesforceSettingContainerProps {
	children: React.ReactNode;
}

export const SalesforceSettingContainer = ({ children }: SalesforceSettingContainerProps) => {
	return (
		<div className={cn('salesforce-container', 'container-fluid', 'rounded', 'p-0')}>
			<div className={cn('p-4', 'pt-1')}>{children}</div>
		</div>
	);
};
