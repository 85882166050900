import { useQuery } from '@tanstack/react-query';
import { QueryPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';

import { useContext } from 'react';

export const useIsSalesforceConnected = ({ shouldRefreshCredentials = false }) => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { authUser }: UserContextType = useContext(LoggedInUserContext);
	return useQuery(
		[QueryKey.SalesforceConnection, authUser?.tenantIdOverride ?? ''],
		() => httpClient.post<{ res: { data: boolean } }>(QueryPath.SalesforceConnection, { refresh: shouldRefreshCredentials }),
		{
			select: ({ data }) => data.res.data,
			staleTime: 10 * 60 * 1000, //millis
			refetchOnWindowFocus: true,
		}
	);
};
