import cn from 'classnames';
import Switch from 'react-switch';
import { FaTrashAlt } from 'react-icons/fa';
import { IoShuffle } from 'react-icons/io5';
import { ImKey2 } from 'react-icons/im';
import { BsArrowRight } from 'react-icons/bs';
import { FieldMappingDropdown } from 'new-beginning/components/pages/IntegrationSettings/field_mapping/FieldMappingDropdown';
import { SourceOrTarget, DropDownOptionType, BFMappingTypes } from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { SalesforceMapping } from 'new-beginning/hooks/useCrmMappingData';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/integration/fieldMapping/FieldDomainType';

// TODO This is a temporary solution to show Broker Office City and Broker Office State as "Matching" fields even though the matching logic
// 		is hardcoded and not based off of the SimpleFieldMapping
// TODO DELETE Once address mappings are fixed for BrokerOffice
const showKeyFieldTemp = (field, objectType) => {
	const brokerOfficeFields = ['broker_office_state', 'broker_office_city'];
	return objectType === BFMappingTypes.BROKER_OFFICE && brokerOfficeFields.includes(field?.sourceField?.name); 
};

interface MappingTableRowProps {
	loading: boolean;
	crmObjectType: BFMappingTypes;
	fieldMappings: SalesforceMapping[];
	sourceFieldSet: DropDownOptionType[];
	targetFieldSet: DropDownOptionType[];
	setMappingField: (mappingIdx: number, values: Partial<SalesforceMapping>) => void;
	deleteFieldMapping: (index: number) => void;
}

export const MappingTableRows = ({
	loading,
	crmObjectType,
	fieldMappings,
	sourceFieldSet,
	targetFieldSet,
	setMappingField,
	deleteFieldMapping,
}: MappingTableRowProps) => {
	return fieldMappings && fieldMappings.length > 0
		? fieldMappings
				.filter((field) => field !== null)
				.map((field, idx) => {
					const {
						id,
						sourceField,
						targetField,
						// Old Columns
						allow_overwrite,
						is_key_field,
						is_deleteable,
					} = field;
					const picklistConstantSelected = sourceField?.field_domain_type === CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT;
					return (
						<tr key={`${id || targetField?.name}-${idx}`}>
							<td>
								<FieldMappingDropdown
									fieldIdx={idx}
									updateFieldMapping={setMappingField}
									sourceOrTarget={SourceOrTarget.SOURCE}
									value={field?.sourceField}
									mappingField={field}
									optionSet={sourceFieldSet}
									isLocked={!is_deleteable}
								/>
							</td>
							<td align='center'>
								{!is_key_field && !sourceField?.value && !showKeyFieldTemp(field, crmObjectType) && <IoShuffle size={22} color='gray' />}
								{
									(is_key_field && !sourceField?.value || showKeyFieldTemp(field, crmObjectType))
										? <ImKey2 size={20} color='gray' />
										: null
								}
								{sourceField?.value && !is_key_field ? <BsArrowRight size={22} color='gray' /> : null}
							</td>
							<td>
								<FieldMappingDropdown
									fieldIdx={idx}
									sourceOrTarget={SourceOrTarget.TARGET}
									value={field?.targetField}
									mappingField={field}
									optionSet={targetFieldSet}
									updateFieldMapping={setMappingField}
									isLocked={!is_deleteable || !sourceField?.name || picklistConstantSelected}
								/>
							</td>
							<td align='center'>
								<Switch
									onColor='#ebf0fc'
									onHandleColor='#185ADB'
									offColor='#ebf0fc'
									offHandleColor='#B7C2CF'
									height={25}
									handleDiameter={25}
									width={50}
									uncheckedIcon={false}
									checkedIcon={false}
									onChange={(value: boolean) => {
										setMappingField(idx, { allow_overwrite: value });
									}}
									checked={allow_overwrite}
								/>
							</td>
							<td align='right'>
								<button
									type='button'
									disabled={loading || !is_deleteable}
									onClick={() => {
										// Existing Fields are stored by ID | Unsaved Mappings are stored by Index
										deleteFieldMapping(id || idx);
									}}
									className={cn(
										'btn',
										loading || !is_deleteable ? 'btn-outline-secondary' : 'btn-outline-danger',
										'py-2',
										'mx-1'
									)}
								>
									<FaTrashAlt size={14} />
								</button>
							</td>
						</tr>
					);
				})
		: null;
};
