export const brokerRoleOptions = [
    { option: 'Account Manager' },
    { option: 'Producer' },
    { option: 'Vendor Specialist' }
];

export const employerRoleOptions = [
    { option: 'Executive' },
    { option: 'HR & Talent' },
    { option: 'Benefits' },
    { option: 'Finance' },
    { option: 'Total Rewards' },
];

export const brokerJobTitleOptions = [
	{ option: 'Account Executive' },
	{ option: 'Account Manager' },
	{ option: 'Advisor' },
	{ option: 'Agent' },
	{ option: 'Associate Vice President' },
	{ option: 'Benefits Admin' },
	{ option: 'Benefits Associate' },
	{ option: 'Benefits Leader' },
	{ option: 'Benefits Manager' },
	{ option: 'Benefits Specialist' },
	{ option: 'Broker' },
	{ option: 'Business Development' },
	{ option: 'Client Manager' },
	{ option: 'Consultant' },
	{ option: 'Director' },
	{ option: 'Executive Vice President' },
	{ option: 'Founder' },
	{ option: 'General H&W Specialist' },
	{ option: 'HR Tech Specialist' },
	{ option: 'Owner' },
	{ option: 'Partner' },
	{ option: 'Pharmacy Specialist' },
	{ option: 'Practice Leader' },
	{ option: 'President' },
	{ option: 'Principal' },
	{ option: 'Producer' },
	{ option: 'Sales' },
	{ option: 'Senior Vice President' },
	{ option: 'Vice President' },
	{ option: 'Voluntary Specialist' },
	{ option: 'Wellness Specialist' },
];

export const employerJobTitleOptions = [
	{ option: 'Benefits Technology' },
	{ option: 'CEO' },
	{ option: 'Vice President of Human Resources' },
	{ option: 'HR Operations Manager' },
	{ option: 'Benefits' },
	{ option: 'Executive Vice President of Benefits' },
	{ option: 'Benefits Strategy' },
	{ option: 'Head of Finance' },
	{ option: 'Program Manager' },
	{ option: 'Benefits Lead' },
	{ option: 'Director of Total Rewards' },
	{ option: 'Workplace Benefits' },
	{ option: 'Benefits Program Manager' },
	{ option: 'Employee Experience' },
	{ option: 'Founder' },
	{ option: 'Human resources Manager' },
	{ option: 'Vice President' },
	{ option: 'Benefits Design' },
	{ option: 'Human Resources Business Partner' },
	{ option: 'Benefits Experience' },
	{ option: 'Compensation' },
	{ option: 'Benefits Director' },
	{ option: 'VP Finance' },
	{ option: 'People Officer' },
	{ option: 'Director Total Rewards' },
	{ option: 'People Services' },
	{ option: 'Wellness' },
	{ option: 'Employee Engagement' },
	{ option: 'Compensation Operations' },
	{ option: 'Total Rewards' },
	{ option: 'VP of HR' },
	{ option: 'Benefits Manager' },
	{ option: 'HR Manager' },
	{ option: 'VP of Human Resources' },
	{ option: 'Chief Human Resources Officer' },
	{ option: 'Benefits Leader' },
	{ option: 'Diversity Equity & Inclusion' },
	{ option: 'Director of Benefits' },
	{ option: 'Chief People Officer' },
	{ option: 'Director of Human Resources' },
	{ option: 'Global Compensation' },
	{ option: 'Financial Benefits' },
	{ option: 'Executive Director of Benefits' },
	{ option: 'Benefit Strategy' },
	{ option: 'VP of Benefits' },
	{ option: 'Director of Finance' },
	{ option: 'Head of Global Benefits' },
	{ option: 'President' },
	{ option: 'Benefits Delivery' },
	{ option: 'COO' },
	{ option: 'CFO' },
	{ option: 'Director of Compensation and Benefits' },
	{ option: 'Human Capital Management' },
	{ option: 'Executive Rewards' },
	{ option: 'HR Consultant' },
	{ option: 'Benefits Specialist' },
	{ option: 'Human Resources Executive' },
	{ option: 'Health & Wellness' },
	{ option: 'CHRO' },
	{ option: 'General H&W Specialist' },
	{ option: 'Corporate Benefits' },
	{ option: 'HR Director' },
	{ option: 'Global Rewards' },
	{ option: 'HR Analyst' },
	{ option: 'HR Services' },
	{ option: 'Compensation Manager' },
	{ option: 'Talent Management' },
	{ option: 'Head of DEI' },
	{ option: 'People Operations' },
	{ option: 'VP of Total Rewards' },
	{ option: 'Benefit Systems Manager' },
	{ option: 'Workforce Planning' },
	{ option: 'Director of Employee Benefits' },
	{ option: 'Benefits Administration' },	
];
