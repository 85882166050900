import { useCallback } from 'react';
import { useAddNotification } from '@benefitflow/designsystem';
import { FormattingUtils } from 'ui-components';

export const useOnPurchaseContactSuccess = ({ callback }: { callback: (data: any) => void }) => {
	const addNotification = useAddNotification();
	const onSuccess = useCallback(
		({ data }: any) => {
			if (!data.res.success) {
				addNotification({
					type: 'error',
					message: data.res?.message || 'An error occurred',
				});
				return;
			}
			callback(data);
			if (data.res?.data?.consumption)
				addNotification({
					type: 'success',
					autoClose: true,
					title: `${FormattingUtils.formatCommas(data.res?.data?.consumption?.creditsJustUsed)} credits used.`,
					message: `Your team has ${FormattingUtils.formatCommas(data.res?.data?.consumption?.totalCreditsRemaining)} of ${FormattingUtils.formatCommas(
						data.res?.data?.consumption?.totalCreditsAllowed,
					)} remaining.`,
				});
			else addNotification({ autoClose: true, type: 'info', message: 'You only get charged for contacts with valid emails.' });
		},
		[callback, addNotification],
	);

	return onSuccess;
};
