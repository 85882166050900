import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { ContainerState } from './types';
import DefaultFilterValues from './DefaultFilterValues';

// The initial state of the FilterBar container
export const initialState: ContainerState = {
  providerFilters: DefaultFilterValues.getProviderFilters(),
  brokerFilters: DefaultFilterValues.getBrokerFilters(),
  employerFilters: DefaultFilterValues.getEmployerFilters(),
  filtersOpenMap: {},
  filtersSearchMap: {},
  isNewBrokerSearch: false,
};

const filterBarSlice = createSlice({
  name: 'filterBar',
  initialState,
  reducers: {
    updateBrokerFilters(state, action: PayloadAction<any>) {
      state.brokerFilters = action.payload['filters'];
      state.isNewBrokerSearch = true
    },
    updateEmployerFilters(state, action: PayloadAction<any>) {
      state.employerFilters = action.payload['filters'];
    },
    updateProviderFilters(state, action: PayloadAction<any>) {
      state.providerFilters = action.payload['filters'];
    },
    updateFiltersOpenMap(state, action: PayloadAction<any>) {
      state.filtersOpenMap = action.payload;
    },
    updateFiltersSearchMap(state, action: PayloadAction<any>) {
      state.filtersSearchMap = action.payload;
    },
    clearFilters(state, action: PayloadAction<any>) {
      switch (action.payload['searchBy']) {
        case 1:
          state.employerFilters = DefaultFilterValues.getEmployerFilters();
          break;
        case 2:
          state.providerFilters = DefaultFilterValues.getProviderFilters();
          break;
        case 0:
          state.isNewBrokerSearch = true;
          state.brokerFilters = DefaultFilterValues.getBrokerFilters();
          break;
      }
    },
  }
});

export const { actions: filterBarActions, reducer, name: sliceKey } = filterBarSlice;