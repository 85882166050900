import { Box, Space, Tooltip, Typography, Icon as DSIcon, Skeleton } from '@benefitflow/designsystem';

import { useRows } from './hooks';
import { Icon } from './components';
import { License } from './VerifiedProducerBadge.types';

export const VerifiedProducerBadge = ({
	licenses,
	loading,
	isVerified,
}: {
	loading?: boolean;
	licenses: License[];
	isVerified: boolean;
}) => {
	const rows = useRows(licenses);

	if (loading) return <Skeleton height='md' width='xl4' />;
	if (!isVerified || licenses?.length < 1) return null;
	return (
		<Tooltip
			behavior='click'
			placement='bottom-start'
			strategy='fixed'
			content={
				<Box p='sm' display='flex' flexDirection='column' gap='sm' style={{ cursor: 'pointer' }}>
					<Typography.Text whiteSpace='nowrap' size='md'>
						Insurance Licenses
					</Typography.Text>
					{rows.map(({ label, checked, since }) => (
						<Box display='flex' justifyContent='between' key={label}>
							<Space size='xs'>
								<Icon checked={checked} />
								<Typography.Text size='sm' weight='medium' whiteSpace='nowrap' color='neutral-600'>
									{label}
								</Typography.Text>
							</Space>
							<Box width='xl3'></Box>
							{since && (
								<Typography.Text size='sm' whiteSpace='nowrap' weight='regular' color='neutral-500'>
									Since {since}
								</Typography.Text>
							)}
						</Box>
					))}
				</Box>
			}
		>
			{(ref) => (
				<Box style={{ cursor: 'pointer' }} display='inline-block' containerRef={ref}>
					<Box
						p='xxs'
						display='flex'
						gap='xxs'
						alignItems='center'
						justifyContent='center'
						backgroundColor='secondary-100'
						borderRadius='sm'
					>
						<DSIcon name='verified-fill' color='secondary-500' size='md' />
						<Typography.Text whiteSpace='nowrap' size='xs' color='neutral-500'>
							Verified Producer
						</Typography.Text>
					</Box>
				</Box>
			)}
		</Tooltip>
	);
};
