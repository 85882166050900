import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { FormattingUtils } from "utils/index";
import { Panel } from 'atoms/Panel';
import { Metric } from 'molecules/Metric';
import { PieChart } from 'molecules/charts/PieChart';

const PieChartSkeleton = styled(Skeleton)`
  padding: 15px;
  border-radius: 7px;
`;

function getPie(map, colors) {
  if (!map) {
    return null;
  }
  const keys = Object.keys(map).sort((a, b) => map[b] - map[a]);
  const values = keys.map(x => map[x]);
  const colorValues = keys.map(x => colors[x]);
  return {
    labels: keys.map(x => FormattingUtils.formatUpperCasing(x)),
    datasets: [
      {
        data: values,
        backgroundColor: colorValues,
        borderWidth: 0,
      },
    ],
  };
}

export interface InsurancePoliciesSummaryCardProps {
  className?: any;
  isLoading: boolean;
  brokerColors: { [key: string]: string };
  carrierColors: { [key: string]: string };
  countMedicalPolicies: number;
  countNonMedicalPolicies: number;
  totalBrokerCommissions: number;
  brokerCommissionsMap: { [key: string]: number };
  totalCarrierPremiums: number;
  carrierPremiumsMap: { [key: string]: number };
}

export const InsurancePoliciesSummaryCard = (props: InsurancePoliciesSummaryCardProps) => {

  const brokerCommissionsPieConfig = getPie(props.brokerCommissionsMap, props.brokerColors);
  const carrierPremiumsPieConfig = getPie(props.carrierPremiumsMap, props.carrierColors);

  return (
    <Panel>
      <div className="">
        <Row>
          <Col lg={3}>
            <Metric
              loading={props.isLoading}
              title="Medical Policies"
              type="tag"
              text={props.countMedicalPolicies?.toString()}
            />
            <Metric
              loading={props.isLoading}
              title="Non-Medical Policies"
              type="tag"
              text={props.countNonMedicalPolicies?.toString()}
            />
          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={6}>
                <Metric
                  loading={props.isLoading}
                  title="Broker Commissions"
                  type="text"
                  text={`\$${FormattingUtils.formatNumberAbbrev(
                    props.totalBrokerCommissions,
                  )} Total`}
                />
                {props.isLoading ? (
                  <PieChartSkeleton variant="rect" width="100%" height={140} />
                ) : (
                  <PieChart
                    data={brokerCommissionsPieConfig}
                    valueTitle={'Broker Commissions'}
                    percentageTitle={'% of Total Commmissions'}
                  />
                )}
              </Col>
              <Col lg={6}>
                <Metric
                  loading={props.isLoading}
                  title="Carrier Premiums"
                  type="text"
                  text={`\$${FormattingUtils.formatNumberAbbrev(
                    props.totalCarrierPremiums,
                  )} Total`}
                />
                {props.isLoading ? (
                  <PieChartSkeleton variant="rect" width="100%" height={140} />
                ) : (
                  <PieChart
                    data={carrierPremiumsPieConfig}
                    valueTitle={'Carrier Premiums'}
                    percentageTitle={'% of Total Premiums'}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Panel>
  );
};