import { useMutation, useQueryClient } from '@tanstack/react-query';
import { MutationKey } from 'new-beginning/constants/mutationKey';
import { MutationPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import { useCallback, useContext } from 'react';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';

export const useDeleteList = () => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const queryClient = useQueryClient();
	const { authUser }: UserContextType = useContext(LoggedInUserContext);

	const { mutate, isLoading } = useMutation({
		mutationKey: [MutationKey.DeleteList],
		mutationFn: (listId: number) => httpClient.post(MutationPath.DeleteList, { listId }),
		onSuccess: (_) => queryClient.invalidateQueries({ queryKey: [QueryKey.Lists, authUser?.tenantIdOverride ?? ''] }),
	});

	const onDelete = useCallback(
		(id: number) => {
			if (id > 0) {
				mutate(id);
			}
		},
		[mutate]
	);
	return { onDelete, isDeleting: isLoading };
};
