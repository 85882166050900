import { Box, Button, Icon, Skeleton, Suspense, Typography } from '@benefitflow/designsystem';
import { useHeader, useNavigation, useOnClickBack, useOnClickExportPDF } from './hooks';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { useIsV2 } from '../hooks/useIsV2';

export const Header = () => {
	const isV2 = useIsV2();
	const getValuesByScreenSize = useGetValueByScreenSizes();
	const { loading, name } = useHeader();
	const onClickBack = useOnClickBack();
	const onClickExportPDF = useOnClickExportPDF();
	const { currentPage, onNextClick, onPreviousClick, totalPages } = useNavigation();

	return (
		<Box
			style={{ borderBottom: '0.5px solid #CBCDD1' }}
			p="md"
			backgroundColor="white"
			display="flex"
			flexDirection={getValuesByScreenSize({ lg: 'row', md: 'row', sm: 'column' })}
			width="full"
			justifyContent="between"
			gap="md"
		>
			<Box display="flex" alignItems="center" gap="sm">
				<Button loading={loading} variant="outlined" onClick={onClickBack}>
					<Icon name="arrow-left" size="md" />
				</Button>
				<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl6" />}>
					<Box display="flex" gap="sm" justifyContent="center" alignItems="center">
						<Typography.Text>Employer Search</Typography.Text>
						<Typography.Text>•</Typography.Text>
						<Typography.Text>{name}</Typography.Text>
					</Box>
				</Suspense>
			</Box>

			{isV2 && (
				<Box display="flex" alignItems="center" gap="sm" justifyContent="center">
					<Button loading={loading} variant="outlined" onClick={onClickExportPDF}>
						<Box display="flex" alignItems="center" gap="sm">
							<Icon name="arrow-leave" size="sm" /> Export PDF
						</Box>
					</Button>
					<Suspense loading={loading} fallback={<Skeleton height="sm" width="xl3" />}>
						<Typography.Text whiteSpace="nowrap" color="neutral-500" size="sm">
							{currentPage} of {totalPages}
						</Typography.Text>
					</Suspense>
					<Button.Group>
						<Button loading={loading} variant="outlined" onClick={onPreviousClick}>
							<Icon name="chevron-left" size="lg" />
						</Button>
						<Button loading={loading} variant="outlined" onClick={onNextClick}>
							<Icon name="chevron-right" size="lg" />
						</Button>
					</Button.Group>
				</Box>
			)}
		</Box>
	);
};
