import React from 'react';
import { DeleteButton, DeleteButtonProps } from 'atoms/Buttons/DeleteButton';
import { DownloadButton, DownloadButtonProps } from 'atoms/Buttons/DownloadButton';
import MoveButton, { MoveButtonProps } from 'atoms/Buttons/MoveButton';
import { CardsList, CardsListSelectAllProps } from 'organisms/CardsList';
import { GetSelectedButton, GetSelectedButtonProps } from 'atoms/Buttons/GetSelectedButton';
import { BrokerContactCard } from 'new-beginning/components/pages/Search/BrokerContactCard/BrokerContactCard';
import { EmployerContactCard } from 'new-beginning/components/pages/Search/EmployerContactCard/EmployerContactCard';
import { formatLocation, isPurchasable } from 'pages/SearchPage/organisms/tabs/ContactTab/helpers';
import DateUtils from 'utils/DateUtils';
import { usePurchaseContactMutation } from 'new-beginning/mutations';
import { useOnPurchaseContactSuccess } from 'new-beginning/hooks/useOnPurchaseContactSuccess';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { useDispatch } from 'react-redux';
import FormattingUtils from 'utils/FormattingUtils';
// import { useOnPurchaseContactSuccess } from './hooks';

export interface ContactsCardsListProps {
	checkedMap: any;
	total: number;
	contacts: any;
	selectedContactsMap: any;
	searchInfinite: () => void;
	isSearchInfiniteLoading: boolean;
	onToggleSelectAll: () => void;
	onToggleChecked: (contactId: string) => void;
	onGetContact: any;
	filters: any;
	delete: DeleteButtonProps;
	move: MoveButtonProps;
	download: DownloadButtonProps;
	selectAll: CardsListSelectAllProps;
	revealSelected: GetSelectedButtonProps;
	contactsChecked: number;
	type: 'broker' | 'employer';
}

export const ContactsCardsList = (props: ContactsCardsListProps) => {
	return (
		<div key={'producer_results'} className="cards" style={{ marginTop: '10px' }}>
			<CardsList
				rows={props.contacts ? props.contacts : null}
				getCard={(row) => {
					const Card = () => {
						const contact = props.selectedContactsMap[row.employee_profile_url];
						const dispatch = useDispatch();
						const onSuccess = useOnPurchaseContactSuccess({ callback: (data) => dispatch(listsDetailPageActions.gotContact(data.res.data.res)) });
						const { mutate, isLoading } = usePurchaseContactMutation({
							onSuccess,
							contactType: props.type.toUpperCase() as 'EMPLOYER' | 'BROKER',
							profileUrl: row.employee_profile_url,
						});
						if (props.type === 'broker')
							return (
								<BrokerContactCard
									gettingContact={isLoading}
									onGetContact={mutate}
									monthsAtCompany={row?.total_months_at_company}
									monthsInRole={row?.current_role_start_date && DateUtils.getNumMonthsBetween(new Date(row.current_role_start_date), new Date())}
									companyName={row?.company_name}
									loading={!row}
									toggle={() => props.onToggleChecked(row.employee_profile_url)}
									checked={props.checkedMap[row.employee_profile_url]}
									imageSrc={row?.profile_pic}
									name={row?.employee_name}
									isVerified={row?.is_benefit_contact}
									isPurchasable={isPurchasable(row)}
									isPurchased={!!contact}
									licenses={row?.broker_contact_licenses ?? []}
									title={row?.experience_title}
									location={formatLocation(row?.employee_location)}
									hasEmail={!!row?.has_email}
									hasPhone={row.has_mobile_phone_1}
									phone={contact?.mobile_phone_1}
									email={contact?.work_email}
									contactLinkedinUrl={row?.employee_profile_url}
									companyLinkedinUrl={row.company_profile_url}
									appointments={row?.broker_contact_carrier_appointments}
									onBrokerClick={() =>
										window.open(
											`${window.location.origin}/Broker` +
												`?brokerId=${row.company_master_id}` +
												`&brokerName=${row?.company_name}` +
												`&brokerFilters=null` +
												`&brokerLocation=${
													row?.broker_office_locations?.length
														? `${row?.broker_office_locations[0].broker_office_city}, ${row?.broker_office_locations[0].broker_office_state_code}`.toLocaleLowerCase()
														: undefined
												}`,
										)
									}
								/>
							);
						if (props.type === 'employer')
							return (
								<EmployerContactCard
									onEmployerClick={() => window.open(`${window.location.origin}/Employer/` + row?.company_master_id)}
									key={row.employee_profile_url + props.checkedMap[row.employee_profile_url]}
									monthsAtCompany={row?.total_months_at_company}
									monthsInRole={row?.current_role_start_date && DateUtils.getNumMonthsBetween(new Date(row.current_role_start_date), new Date())}
									companyName={FormattingUtils.formatUpperCasing(row?.company_name)}
									loading={!row}
									fetching={isLoading}
									toggle={() => props.onToggleChecked(row.employee_profile_url)}
									checked={props.checkedMap[row.employee_profile_url]}
									imageSrc={row?.profile_pic}
									name={row?.employee_name}
									isPurchasable={isPurchasable(row)}
									isPurchased={!!contact}
									title={row?.experience_title}
									location={formatLocation(row?.employee_location)}
									hasEmail={row?.has_email}
									hasPhone={row?.has_mobile_phone_1}
									email={contact?.work_email}
									phone={contact?.mobile_phone_1}
									contactLinkedinUrl={row?.employee_profile_url}
									onGetContact={mutate}
								/>
							);
					};
					return <Card />;
				}}
				getLoadingCard={() => {
					if (props.type === 'broker') return <BrokerContactCard loading />;
					if (props.type === 'employer') return <EmployerContactCard loading />;
				}}
				searchInfinite={() => props.searchInfinite()}
				isInfiniteLoading={props.isSearchInfiniteLoading}
				buttons={[
					<GetSelectedButton title="Get Selected" {...props.revealSelected} />,
					<DeleteButton title="Delete Selected" {...props.delete} />,
					<MoveButton title="Move Selected" {...props.move} />,
					<DownloadButton title="Export Selected" {...props.download} />,
				]}
				selectAll={props.selectAll}
			/>
		</div>
	);
};
