import { MouseEvent } from 'react';

export const openLinkInTab = (url: string): ((e: MouseEvent<Element>) => void) => {
	const clickHandler = (e: MouseEvent<Element>) => {
		e.stopPropagation();
		if (!!url) {
			window.open(url, '_blank');
		}
	};

	return clickHandler;
};

export const delayResolve = (ms): Promise<void> => {
	return new Promise(resolve => setTimeout(resolve, ms));
};
