import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { AutoCompleteProps } from '@benefitflow/designsystem/dist/components/AutoComplete/AutoComplete.types';

import { getLabel } from 'new-beginning/components/shared/SidebarFilters/components/OfficeLocation/helpers';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { LocationType } from 'common.model/src/types/location/location';

export const useOptions = () => {
	const { cityStateSearchOptions } = useSelector(selectListsDetailPage);

	const options = useMemo<AutoCompleteProps<any>['options']>(
		() =>
			cityStateSearchOptions?.map((data) => ({
				value: data.searchValue,
				label: getLabel({ location: data }),
				data,
				removable: data.locationType !== LocationType.Zip,
			})) ?? [],
		[cityStateSearchOptions],
	);

	return options;
};
