import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { BrokerContactFilters, ContainerState } from './types';
import { BreakdownResponse } from 'common.services/src/requests/Breakdowns';

const defaultContactFilters: BrokerContactFilters = {
	companyName: {
		include: [],
		exclude: [],
	},
	contactLocation: {
		exclude: [],
		include: [],
	},
	licenseTypes: { exclude: [], include: [] },
	carrierAppointments: { exclude: [], include: [] },
	officeLocation: { exclude: [], include: [] },
	roles: { exclude: [], include: [] },
	jobTitles: { exclude: [], include: [] },
	yearsAtCompany: ['0', '21'],
	search: '',
	brokerSize: { exclude: [], include: [] },
};

// The initial state of the FilterBar container
export const initialState: ContainerState = {
	brokerContactFilters: defaultContactFilters,
	isContactsLoaded: false,
	isContactsLoading: false,
	isContactsSearchInfiniteLoading: false,
	anonymizedBrokerContactResults: null,
	selectedBrokerContactResults: null,
	brokerContactResultsBreakdown: null,
	brokerContactSearchInfinitePage: 0,

	contactLocationOptions: null,
	contactLocationSearch: '',
	isContactLocationOptionsLoading: false,

	brokerOfficeOptionResults: null,
	brokerOfficeOptionSearch: '',
	isBrokerOfficeOptionsLoading: false,

	checkedMap: {},
	notCheckedMap: {},
	isSelectAll: false,

	gettingSelectedMap: {},

	isDownloading: false,

	showCreateContactListModal: false,

	loadingAddSelected: false,
	addSelectedListId: null,

	selectedContactProfileUrl: null,

	showPurchaseModal: false,
	purchaseModalAmount: null,
};

const brokerSearchPageSlice = createSlice({
	name: 'brokerSearchPage',
	initialState,
	reducers: {
		loadContacts(state) {
			state.isContactsLoading = true;
		},
		searchBrokerOfficeOptions(state, action: PayloadAction<string>) {
			state.isBrokerOfficeOptionsLoading = true;
			state.brokerOfficeOptionSearch = action.payload;
		},
		updateBrokerOfficeOptionsResults(state, action: PayloadAction<any>) {
			state.brokerOfficeOptionResults = action.payload;
			state.isBrokerOfficeOptionsLoading = false;
		},
		loadedContacts(state) {
			state.isContactsLoading = false;
			state.isContactsLoaded = true;
		},
		clearContactFilters: () => initialState,
		updateBrokerContactFilters(state, action: PayloadAction<any>) {
			state.brokerContactFilters = action.payload['filters'];
			// clear state when filters change
			state.checkedMap = {};
			state.notCheckedMap = {};
			state.isSelectAll = false;
			state.anonymizedBrokerContactResults = null;
			state.selectedBrokerContactResults = null;
			state.brokerContactResultsBreakdown = null;
			state.brokerContactSearchInfinitePage = 0;
			state.contactLocationSearch = '';
		},
		searchInfinite(state) {
			state.isContactsSearchInfiniteLoading = true;
			state.brokerContactSearchInfinitePage += 1;
		},
		updateBrokerContactResults(state, action: PayloadAction<any>) {
			state.anonymizedBrokerContactResults = action.payload['anonymizedContacts'];
			state.selectedBrokerContactResults = action.payload['selectedContacts']?.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, {});
		},
		updateInifinteBrokerContactResults(state, action: PayloadAction<any>) {
			state.anonymizedBrokerContactResults = state.anonymizedBrokerContactResults.concat(action.payload['anonymizedContacts']);
			state.selectedBrokerContactResults = action.payload['selectedContacts']?.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, state.selectedBrokerContactResults);
			state.isContactsSearchInfiniteLoading = false;
		},
		updateBrokerContactResultsBreakdown(state, action: PayloadAction<BreakdownResponse>) {
			state.brokerContactResultsBreakdown = action.payload;
		},
		searchContactLocation(state, action: PayloadAction<string>) {
			state.isContactLocationOptionsLoading = true;
			state.contactLocationSearch = action.payload;
		},
		updateContactLocationOptionsResults(state, action: PayloadAction<any>) {
			state.contactLocationOptions = action.payload;
			state.isContactLocationOptionsLoading = false;
		},
		updateCheckedMap(state, action: PayloadAction<any>) {
			state.checkedMap = action.payload;
		},
		updateNotCheckedMap(state, action: PayloadAction<any>) {
			state.notCheckedMap = action.payload;
		},
		setIsSelectAll(state, action: PayloadAction<any>) {
			state.isSelectAll = action.payload;
		},
		getSelected(state, action: PayloadAction<string[]>) {
			state.gettingSelectedMap = action.payload.reduce((a, b) => {
				a[b] = true;
				return a;
			}, state.gettingSelectedMap);
		},
		gotSelected(state, action: PayloadAction<any>) {
			state.selectedBrokerContactResults = action.payload.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, state.selectedBrokerContactResults);
			// state.checkedMap = Object.keys(state.gettingSelectedMap)
			//   .reduce((a,b) => {
			//     delete a[b];
			//     return a;
			//   }, state.checkedMap);
			state.gettingSelectedMap = {};
		},
		downloadSearchResults(state) {
			state.isDownloading = true;
		},
		downloadedSearchResults(state) {
			state.isDownloading = false;
		},
		toggleCreateContactListModal(state) {
			state.showCreateContactListModal = !state.showCreateContactListModal;
		},
		addSelectedToNewList(state, action: PayloadAction<{ listName: string; listDescription: string }>) {
			state.loadingAddSelected = true;
		},
		addedSelectedToNewList(state) {
			state.loadingAddSelected = false;
		},
		addSelectedToExistingList(state, action: PayloadAction<any>) {
			state.loadingAddSelected = true;
			state.addSelectedListId = action.payload;
		},
		addedSelectedToExistingList(state) {
			state.loadingAddSelected = false;
			state.addSelectedListId = null;
		},
		getContact(state, action: PayloadAction<any>) {
			state.selectedContactProfileUrl = action.payload;
		},
		gotContact(state, action: PayloadAction<any>) {
			state.selectedBrokerContactResults[action.payload.profile_url] = action.payload;
			state.selectedContactProfileUrl = null;
		},
		showPurchaseModal(state, action: PayloadAction<number>) {
			state.showPurchaseModal = true;
			state.purchaseModalAmount = action.payload;
		},
		hidePurchaseModal(state) {
			state.showPurchaseModal = false;
			state.purchaseModalAmount = null;
		},
	},
});

export const { actions: brokerSearchPageActions, reducer, name: sliceKey } = brokerSearchPageSlice;
