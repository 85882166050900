import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import RouterUtils from 'app/RouterUtils';
import { useEmployerSummaryQuery } from 'new-beginning/queries';

export const useOnBrokerClick = () => {
	const { employerId, employer_master_id } = useParams();
	const { data } = useEmployerSummaryQuery({ employerId: employerId || employer_master_id });

	const navigate = useNavigate();
	const onBrokerClick = useCallback(() => {
		if (!data) {
			console.error('No data found');
			return;
		}
		navigate(
			RouterUtils.getBrokerPath(
				data.primary_broker_unique_code,
				data.primary_broker,
				null,
				null,
				data.primary_broker_closest_office_city && data.primary_broker_closest_office_state
					? `${data.primary_broker_closest_office_city}, ${data.primary_broker_closest_office_state}`
					: null,
				data.employer_master_id,
			),
		);
	}, [data, navigate]);

	return onBrokerClick;
};
