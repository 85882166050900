import './style.scss';
import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';
import { SlideDown } from 'react-slidedown';
import { ChevronUp, ChevronDown } from 'react-iconly';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { unknownBusinessLogo } from 'assets/index';
import { FormattingUtils } from 'utils/index';
import { SecondarySpanMedium } from 'atoms/StyledComponents';
import { terciaryColor } from 'designTokens/colors';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		link_hover: {
			'&:hover': {
				textDecoration: 'underline !important',
				color: terciaryColor,
				cursor: 'pointer',
			},
		},
	}),
);

export interface BrokerSummaryCardProps {
	className?: any;
	brokerName?: string;
	numOffices?: number;
	totalClients?: number;
	totalCommissions?: number;
	totalPremiums?: number;
	companyInfo?: any;
	numContacts: number;
	imageUrl: string;
}

export const BrokerSummaryCard = (props: BrokerSummaryCardProps) => {
	const classes = useStyles();
	const [showBrokerInfo, setShowBrokerInfo] = useState(false);
	const handleCloseBrokerInfo = () => setShowBrokerInfo(false);
	const handleShowBrokerInfo = () => setShowBrokerInfo(true);

	return (
		<div className={`brocker-card ${props.className}`} style={{ padding: '25px' }}>
			<Row className="align-items-center">
				<Col sm={2}>
					<div className="card-img">
						{props.companyInfo ? (
							<img
								src={props.imageUrl}
								onError={({ currentTarget }) => {
									currentTarget.onerror = () => {
										currentTarget.src = unknownBusinessLogo;
										currentTarget.onerror = null; // prevents further looping
									};
									currentTarget.src = `https://logo.clearbit.com/${props?.companyInfo?.website}`;
								}}
								style={{ width: '100px', borderRadius: '20px' }}
							></img>
						) : (
							<img style={{ width: '100px', borderRadius: '20px' }} src={unknownBusinessLogo} alt="" className="frame2" />
						)}
					</div>
				</Col>
				<Col sm={10}>
					{/* <Col sm={10}> */}
					<div className="card-detail">
						<div>
							<h3 className="bd-name">{FormattingUtils.formatUpperCasing(props.brokerName)}</h3>
							<span className="bd-address">{props.companyInfo?.headquarters ? `HQ in ${props.companyInfo?.headquarters}` : null}</span>
							<div className="bd-actions">
								{props.companyInfo?.website ? (
									<a href={`https://${props.companyInfo?.website}`} target="_blank" className={classes.link_hover}>
										Visit Website
									</a>
								) : null}
								{props.companyInfo?.linkedin_profile ? (
									<a href={`https://${props.companyInfo?.linkedin_profile}`} target="_blank" className={classes.link_hover}>
										Visit LinkedIn
									</a>
								) : null}
							</div>
						</div>
						<div className="broker-stats">
							<SecondarySpanMedium style={{ fontWeight: 700 }}>
								${FormattingUtils.formatNumberAbbrev(props.totalCommissions)} Commissions Reported
							</SecondarySpanMedium>
							<span style={{ marginTop: '7px' }}>${FormattingUtils.formatNumberAbbrev(props.totalPremiums)} Carrier Premiums</span>
							<span>{props.numOffices} Office Locations</span>
							<span>{props.totalClients} Clients</span>
							<span>{props.numContacts} Contacts</span>
						</div>
					</div>
					<div style={{ paddingTop: '15px' }}>
						{showBrokerInfo ? (
							<a onClick={handleCloseBrokerInfo} style={{ fontWeight: 300 }}>
								See more
								<ChevronUp set="light" style={{ marginLeft: '2px' }} size={20} />
							</a>
						) : (
							<a onClick={handleShowBrokerInfo} style={{ fontWeight: 300 }}>
								See more
								<ChevronDown set="light" style={{ marginLeft: '2px' }} size={20} />
							</a>
						)}
					</div>
				</Col>
			</Row>
			<Row>
				<Col sm={2} />
				<Col sm={10}>
					<div className="c-content">
						<SlideDown className={'my-dropdown-slidedown'} style={{ overflow: 'hidden' }}>
							{showBrokerInfo ? (
								<div style={{ marginTop: '20px' }}>
									<p className="panel-desc" style={{ marginTop: '20px', whiteSpace: 'pre-line' }}>
										{props.companyInfo?.description}
									</p>
								</div>
							) : null}
						</SlideDown>
					</div>
				</Col>
			</Row>
		</div>
	);
};
