import { FC, useState } from 'react';
import { ListTableHeader } from './ListTableHeader';
import { ListRowSet } from './ListRowSet';
import { useGetList } from '../../../../hooks/lists/useGetList';
import cn from 'classnames';
import { DeleteListModal } from 'pages/ListsPage/molecules/DeleteListModal';
import { useDisclosure } from 'new-beginning/hooks/useDisclosure';
import { useDeleteList } from '../../../../hooks/lists/useDeleteList';
interface ListTableProps {
	isSalesforceVisible: boolean;
}
export const ListTable: FC<ListTableProps> = ({ isSalesforceVisible }) => {
	const { isOpen, onClose, onOpen } = useDisclosure();
	const { onDelete, isDeleting } = useDeleteList();
	const [listToDelete, setListToDelete] = useState<number | null>(null);

	const { data, isLoading, handleSorting, currentSorting } = useGetList();

	const handleSelectDelete = (listId: number) => {
		setListToDelete(listId);
		onOpen();
	};

	const handleDelete = (listId: number) => {
		onDelete(listId);
		onClose();
	};

	return (
		<>
			<div className={cn('bf-card-container', 'my-2', 'bg-white', 'rounded', 'mx-2')}>
				<div className={cn('bf-table-container', 'mb-0', 'rounded')}>
					<table className={cn('table', 'bf-table', 'mb-0', 'rounded')}>
						<ListTableHeader paginationParams={currentSorting} setSortConfig={handleSorting} />
						<ListRowSet
							isLoading={isLoading || isDeleting}
							data={data}
							isSalesforceVisible={isSalesforceVisible}
							handleDelete={handleSelectDelete}
						/>
					</table>
				</div>
			</div>
			<DeleteListModal
				isOpen={isOpen}
				onDelete={() => handleDelete(listToDelete)}
				onClose={onClose}
				list={data?.find((list) => list.id === listToDelete)}
			/>
		</>
	);
};
