export enum QueryPath {
	List = 'lists/getAllListsForUser',
	EmployeeSentiment = 'employer/getEmployerInsights',
	SalesforceConnection = 'crm-integration/isConnected',
	EmployeeSummary = 'employer/getEmployerSummary',
}

export enum MutationPath {
	DeleteList = 'lists/deleteList',
}
