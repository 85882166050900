import { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { filterBarActions } from '../FilterBar/slice';
import { ContainerState } from './types';
import { CityStateOption } from 'atoms/filters/drop/CityStateDropDown';
import ExportType from 'common.model/src/types/data-export/ExportType';
import { EntityLocation, LocationSelection } from 'common.model/src/types/location/location';

export function getInitialContactFilters() {
	return {
		companyMasterIds: [],
		licenseTypes: { include: [], exclude: [] },
		carrierAppointments: { include: [], exclude: [] },
		jobTitles: { include: [], exclude: [] },
		yearsAtCompany: ['0', '21'],
		roles: { include: [], exclude: [] },
		contactLocation: { include: [], exclude: [] },
		officeLocation: { include: [], exclude: [] },
	};
}

// The initial state of the BrokerPage container
export const initialState: ContainerState = {
	brokerId: null,
	brokerName: null,

	brokerOffices: null,
	brokerPlansBreakDown: null,

	contactFilters: {
		filters: getInitialContactFilters(),
		cityStateSearchOptions: null,
		isCityStateSearchOptionsLoading: false,
		cityStateSearch: null,
	},
	companyInfo: null,
	anonymizedContacts: [],
	anonymizedContactsBreakdown: null,
	selectedLocation: null,
	officeLocationOpenMap: {},

	selectedOfficeLocation: null,
	selectedOfficeBrokerCity: null,
	selectedOfficeBrokerState: null,
	selectedOfficeEmployer: null,
	officePlansMap: {},

	originPlanUniqueKey: null,
	originPlanOpened: false,

	searchBy: 0,
	selectedContacts: {},
	selectedContactProfileUrl: null,

	isContactsSearchInfiniteLoading: false,
	contactSearchInfinitePage: 0,
	groupBy: 'Closest Broker Office',

	contactsCheckedMap: {},
	contactsNotCheckedMap: {},

	selectAllContactsStarted: false,

	showCreateContactListModal: false,
	clientsPage: 0,

	filtersOpenMap: {},
	filterSearchMap: {},
	employerHqSearch: '',
	brokerOfficeOptionSearch: '',
	carrierSearch: '',

	brokerEmployerHqResults: [],
	isBrokerEmployerHqOptionsLoading: false,
	brokerOfficeOptionResults: [],
	isBrokerOfficeOptionsLoading: false,
	brokerCarrierResults: null,
	isBrokerCarrierOptionsLoading: false,

	showPurchaseModal: false,
	purchaseModalAmount: null,

	isSelectAll: false,
	listAddLoading: false,
	isBrokerOfficesLoading: false,
};

const brokerPageSlice = createSlice({
	name: 'brokerPage',
	initialState,
	reducers: {
		load(state, action: PayloadAction<any>) {
			state.contactFilters = {
				...state.contactFilters,
				filters: {
					...getInitialContactFilters(),
					companyMasterIds: [action.payload['brokerId']],
				},
			};
			state.brokerId = action.payload['brokerId'];
			state.brokerName = action.payload['brokerName'];
			if (action.payload['originPlanUniqueKey']) {
				state.originPlanUniqueKey = action.payload['originPlanUniqueKey'];
			}
			state.officeLocationOpenMap = {};
			state.originPlanOpened = false;
			state.companyInfo = null;
			state.anonymizedContacts = null;
			state.anonymizedContactsBreakdown = null;
			state.searchBy = 0;
			state.selectedContacts = {};

			state.selectedOfficeLocation = null;
			state.selectedOfficeBrokerCity = null;
			state.selectedOfficeBrokerState = null;
			state.selectedOfficeEmployer = null;

			state.officePlansMap = {};
			state.brokerPlansBreakDown = null;
			state.brokerOffices = null;
			state.contactsCheckedMap = {};

			state.filtersOpenMap = {};
			state.filterSearchMap = {};
			state.employerHqSearch = '';
			state.brokerOfficeOptionSearch = '';
			state.carrierSearch = '';

			state.brokerEmployerHqResults = [];
			state.isBrokerEmployerHqOptionsLoading = false;
			state.brokerOfficeOptionResults = [];
			state.isBrokerOfficeOptionsLoading = false;
		},
		updateBrokerCompanyInfo(state, action: PayloadAction<any>) {
			state.companyInfo = action.payload;
		},
		updateBrokerOffices(state, action: PayloadAction<any>) {
			state.brokerOffices = action.payload[0];
		},
		setBrokerOfficesLoading(state, action: PayloadAction<boolean>) {
			state.isBrokerOfficesLoading = action.payload;
		},
		updateBrokerPlansBreakDown(state, action: PayloadAction<any>) {
			state.brokerPlansBreakDown = action.payload;
		},
		searchCityStateOptions(state, action: PayloadAction<string>) {
			state.contactFilters.isCityStateSearchOptionsLoading = true;
			state.contactFilters.cityStateSearch = action.payload;
		},
		updateCityStateOptionsResults(state, action: PayloadAction<any>) {
			state.contactFilters.cityStateSearchOptions = action.payload;
			state.contactFilters.isCityStateSearchOptionsLoading = false;
			state.contactFilters.cityStateSearch = '';
		},
		updateContactFilters(state, action: PayloadAction<any>) {
			state.contactFilters.filters = action.payload;
			state.contactFilters.filters.companyMasterIds = [state.brokerId];
			state.contactFilters.cityStateSearch = '';
			state.contactsCheckedMap = {};
			state.contactsNotCheckedMap = {};
			state.anonymizedContacts = null;
			state.anonymizedContactsBreakdown = null;
			state.contactSearchInfinitePage = 0;
		},
		seeContacts(state, action: PayloadAction<LocationSelection>) {
			const filters = {
				...getInitialContactFilters(),
				officeLocation: { include: [action.payload], exclude: [] },
				companyMasterIds: [state.brokerId],
			};
			state.contactFilters = {
				...state.contactFilters,
				filters,
				cityStateSearch: '',
			};
			state.searchBy = 1;
			state.isSelectAll = false;
			state.contactsCheckedMap = {};
			state.contactsNotCheckedMap = {};
			state.anonymizedContacts = null;
			state.anonymizedContactsBreakdown = null;
			state.contactSearchInfinitePage = 0;
		},
		getAnonymizedContacts(state, action: PayloadAction<any>) {
			state.selectedLocation = action.payload;
			(state.isSelectAll = false), (state.contactsCheckedMap = {});
			state.contactsNotCheckedMap = {};
		},
		updateAnonymizedContacts(state, action: PayloadAction<any>) {
			const existingContacts = state.anonymizedContacts || [];
			state.anonymizedContacts = existingContacts.concat(action.payload['anonymizedContacts']);
			state.selectedContacts = action.payload['selectedContacts']?.reduce((a, b) => {
				a[b.profile_url] = b;
				return a;
			}, state.selectedContacts || {});
			state.isContactsSearchInfiniteLoading = false;
		},
		updateAnonymizedContactsBreakdown(state, action: PayloadAction<any>) {
			state.anonymizedContactsBreakdown = action.payload;
		},
		getContact(state, action: PayloadAction<any>) {
			state.selectedContactProfileUrl = action.payload;
		},
		gotContact(state, action: PayloadAction<any>) {
			state.selectedContacts[action.payload.profile_url] = action.payload;
			state.selectedContactProfileUrl = null;
		},
		collapseAllOfficeLocation(state) {
			state.selectedOfficeLocation = null;
			state.selectedOfficeBrokerCity = null;
			state.selectedOfficeBrokerState = null;
			state.selectedOfficeEmployer = null;
			state.officeLocationOpenMap = {};
		},
		collapseOtherOfficeLocations(state, action: PayloadAction<any>) {
			state.officeLocationOpenMap = {
				[action.payload]: state.officeLocationOpenMap[action.payload],
			};
		},
		toggleOfficeLocationOpen(state, action: PayloadAction<{ location: EntityLocation; locationKey: string; employerMasterId: string }>) {
			const {
				location: { city, stateCode },
				locationKey,
				employerMasterId,
			} = action.payload;
			if (state.officeLocationOpenMap[locationKey]) {
				state.selectedOfficeLocation = null;
				state.selectedOfficeBrokerCity = null;
				state.selectedOfficeBrokerState = null;
				state.selectedOfficeEmployer = null;
				delete state.officeLocationOpenMap[locationKey];
			} else {
				state.selectedOfficeLocation = locationKey;
				state.selectedOfficeBrokerCity = city;
				state.selectedOfficeBrokerState = stateCode;
				state.selectedOfficeEmployer = employerMasterId;
				state.officeLocationOpenMap[locationKey] = true;
			}
		},
		updateOfficePlansMap(state, action: PayloadAction<any>) {
			const location = action.payload['location'];
			const plans = action.payload['plans'];
			const cpy = Object.assign({}, state.officePlansMap);
			cpy[location] = plans;
			state.officePlansMap = cpy;
		},
		toggleOriginLocationOpened(state) {
			state.originPlanOpened = true;
		},
		updateSearchBy(state, action: PayloadAction<any>) {
			state.searchBy = action.payload;
		},
		searchContactsInfinite(state) {
			state.isContactsSearchInfiniteLoading = true;
			state.contactSearchInfinitePage += 1;
		},
		downloadBrokerEmployers(state, action: PayloadAction<ExportType>) {},
		downloadContacts(state) {},
		updateGroupBy(state, action: PayloadAction<any>) {
			console.log(`updateGroupBy: ${action.payload}`);
			state.groupBy = action.payload;
			state.brokerOffices = null;
			state.brokerPlansBreakDown = null;
			state.officePlansMap = {};
			state.officeLocationOpenMap = {};

			state.selectedOfficeLocation = null;
			state.selectedOfficeBrokerCity = null;
			state.selectedOfficeBrokerState = null;
			state.selectedOfficeEmployer = null;
		},
		downloadStarted(state, action: PayloadAction<boolean>) {
			state.downloadStarted = action.payload;
		},
		updateContactCheckedMap(state, action: PayloadAction<any>) {
			state.contactsCheckedMap = action.payload;
		},
		updateContactNotCheckedMap(state, action: PayloadAction<any>) {
			state.contactsNotCheckedMap = action.payload;
		},
		setIsSelectAll(state, action: PayloadAction<any>) {
			state.isSelectAll = action.payload;
		},
		selectAllContacts(state) {
			state.selectAllContactsStarted = true;
		},
		updateSelectedContacts(state, action: PayloadAction<any>) {
			state.selectedContacts = action.payload;
			state.selectAllContactsStarted = false;
		},
		toggleCreateContactListModal(state) {
			state.showCreateContactListModal = !state.showCreateContactListModal;
		},
		setClientsPage(state, action: PayloadAction<any>) {
			state.clientsPage = action.payload;
		},
		searchBrokerOfficeOptions(state, action: PayloadAction<string>) {
			state.brokerOfficeOptionSearch = action.payload;
			state.isBrokerOfficeOptionsLoading = true;
		},
		searchEmployerHq(state, action: PayloadAction<string>) {
			state.employerHqSearch = action.payload;
			state.isBrokerEmployerHqOptionsLoading = true;
		},
		searchBrokerCarrierOptions(state, action: PayloadAction<string>) {
			state.carrierSearch = action.payload;
			state.isBrokerCarrierOptionsLoading = true;
		},
		updateBrokerCarrierOptions(state, action: PayloadAction<any>) {
			state.brokerCarrierResults = action.payload;
			state.isBrokerCarrierOptionsLoading = false;
		},
		updateBrokerOfficeOptions(state, action: PayloadAction<any>) {
			state.brokerOfficeOptionResults = action.payload;
			state.isBrokerOfficeOptionsLoading = false;
		},
		updateEmployerHqOptions(state, action: PayloadAction<any>) {
			state.brokerEmployerHqResults = action.payload;
			state.isBrokerEmployerHqOptionsLoading = false;
		},
		showPurchaseModal(state, action: PayloadAction<number>) {
			state.showPurchaseModal = true;
			state.purchaseModalAmount = action.payload;
		},
		hidePurchaseModal(state) {
			state.showPurchaseModal = false;
			state.purchaseModalAmount = null;
		},
		addBrokerContactsToExistingList(state, action: PayloadAction<{ listId: string }>) {
			state.listAddLoading = true;
		},
		addBrokerContactsToNewList(state, action: PayloadAction<{ listName: string; listDescription }>) {
			state.listAddLoading = true;
		},
		addBrokerContactsToListFinished(state) {
			state.listAddLoading = false;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(filterBarActions.updateBrokerFilters, (state, action) => {
				state.brokerOffices = null;
				state.brokerPlansBreakDown = null;
				state.officePlansMap = {};
				state.officeLocationOpenMap = {};
			})
			.addCase(filterBarActions.clearFilters, (state, action) => {
				state.officePlansMap = {};
				state.brokerPlansBreakDown = null;
				state.brokerOffices = null;
				state.officeLocationOpenMap = {};
			});
	},
});

export const { actions: brokerPageActions, reducer, name: sliceKey } = brokerPageSlice;
