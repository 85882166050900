import React from 'react';
import { Tab, TabPanel, Tabs } from 'molecules/Tabs';
import FilterPageTemplate from 'templates/FilterPageTemplate';
import { ListDetailSummaryCard, ListDetailSummaryCardProps } from '../molecules/ListDetailSummaryCard';
import { ContactsCardsList, ContactsCardsListProps } from '../organisms/ContactsCardsList';
import ContactFilters, { ContactFiltersProps } from 'organisms/ContactFilters/ContactFilters';
import { MoveContactListChooserModal, MoveContactListChooserModalProps } from '../organisms/MoveContactListChooserModal';
import { FilterBar } from 'organisms/FilterBar';
import { ListDetails } from 'new-beginning/components/shared/SidebarFilters/components/Tabs/components';

export const BROKER_CONTACT_TAB = 0;
export const EMPLOYER_CONTACT_TAB = 1;

export interface ListsDetailPageProps {
	moveContactListChooserModal: MoveContactListChooserModalProps;
	onBack: () => void;
	contactsFilterBar: ContactFiltersProps;
	contactsCardList: ContactsCardsListProps;
	summaryCard: ListDetailSummaryCardProps;
	onClearContactFilters: () => void;
	contactTypeTab: number;
	setContactTypeTab: (contactTypeTabNum: number) => void;
}

export const ListsDetailPage = (props: ListsDetailPageProps) => {
	return (
		<>
			<MoveContactListChooserModal {...props.moveContactListChooserModal} />
			<FilterPageTemplate
				title={'Lists'}
				onBack={props.onBack}
				filterBar={<ListDetails />}
				summaryTile={
					<div className="dev-detail-panel">
						<ListDetailSummaryCard {...props.summaryCard} />
					</div>
				}
				mainContent={
					<>
						<Tabs
							value={props.contactTypeTab}
							onChange={(e, v) => {
								props.setContactTypeTab(v);
							}}
						>
							<Tab label="Broker Contacts" />
							<Tab label="Employer Contacts" />
						</Tabs>
						<TabPanel value={props.contactTypeTab} index={BROKER_CONTACT_TAB}>
							<ContactsCardsList {...props.contactsCardList} type="broker" contacts={props.contactsCardList.contacts} />
						</TabPanel>
						<TabPanel value={props.contactTypeTab} index={EMPLOYER_CONTACT_TAB}>
							<ContactsCardsList type="employer" {...props.contactsCardList} contacts={props.contactsCardList.contacts} />
						</TabPanel>
					</>
				}
			/>
		</>
	);
};
