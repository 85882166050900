import { RADIUS_SEARCH_DEFAULT } from 'app/constants';
import { selectListsDetailPage } from 'app/containers/ListsPage/containers/ListsDetailPage/selectors';
import { listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';
import { LocationType } from 'common.model/src/types/location/location';
import { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const useRadiusSearch = () => {
	const [radius, setRadius] = useState<number>(RADIUS_SEARCH_DEFAULT);
	const { contactFilters } = useSelector(selectListsDetailPage);
	const dispatch = useDispatch();

	const onSelectRadius = useCallback(
		(rawRadius) => {
			const newRadius = parseInt(rawRadius);
			setRadius(newRadius);
			const locationIncludes = contactFilters.contactLocation.include.map((location) => ({ ...location, radiusMiles: newRadius }));
			const locationExcludes = contactFilters.contactLocation.exclude.map((location) => ({ ...location, radiusMiles: newRadius }));
			dispatch(
				listsDetailPageActions.updateContactFilters({
					...contactFilters,
					contactLocation: {
						include: locationIncludes,
						exclude: locationExcludes,
					},
				}),
			);
		},
		[dispatch, contactFilters, setRadius],
	);

	const shouldShowRadiusSearch = useMemo(
		() =>
			(contactFilters.contactLocation.include.length > 0 &&
				contactFilters.contactLocation.include.some((location) => location.location.locationType === LocationType.Zip)) ||
			(contactFilters.contactLocation.exclude.length > 0 &&
				contactFilters.contactLocation.exclude.some((location) => location.location.locationType === LocationType.Zip)),
		[contactFilters.contactLocation],
	);
	return {
		shouldShowRadiusSearch,
		radius,
		onSelectRadius,
	};
};
