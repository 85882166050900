import { SourceOrTarget, DropDownOptionType, GroupedOptionSet, SalesforceField, MappingFieldKeys } from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/integration/fieldMapping/FieldDomainType';

const { BENEFIT_FLOW, SALESFORCE, SALESFORCE_CONSTANT, CUSTOM_CONSTANT } = CrmIntegrationFieldDomainType;
const { SOURCE, TARGET } = SourceOrTarget;

// Option Setting and Label Formatting Utilities
export const getFieldMappingUpdate = (
        valueToSet: DropDownOptionType,
        sourceOrTarget: SourceOrTarget,
        picklistConstantSelected: boolean
    ) => {
    const fieldToUpdate = MappingFieldKeys[sourceOrTarget];

    const valueMapped: Partial<SalesforceField> =
        valueToSet.field_domain_type === SALESFORCE_CONSTANT
            ? {
                    [fieldToUpdate]: {
                        name: valueToSet?.name,
                        field_domain_type: valueToSet?.field_domain_type,
                        value: valueToSet?.picklistValue?.value,
                    },
                    [MappingFieldKeys.TARGET]: {
                        name: valueToSet?.name,
                        field_domain_type: SALESFORCE,
                    },
                }
            : {
                [fieldToUpdate]: {
                    name: valueToSet?.name,
                    field_domain_type: valueToSet?.field_domain_type,
                    ...(valueToSet?.value ? { value: valueToSet?.value } : {}),
                },
                ...// Unset Target Values when Editing an existing PICKLIST FIElD since the Target is auto-set
                (picklistConstantSelected
                    ? {
                            [MappingFieldKeys.TARGET]: {},
                        }
                    : {}),
            };
    return valueMapped;
};

export const formatOptionLabel = (option: DropDownOptionType, sourceOrTarget: SourceOrTarget) => {
    if (option?.field_domain_type === SALESFORCE_CONSTANT) {
        return sourceOrTarget === SOURCE
            ? `"${option?.picklistValue?.label}" (${option?.label} Constant)`
            : `${option?.label} (${option?.type})`;
    } else if (option?.field_domain_type === CUSTOM_CONSTANT) {
        return `${option?.value} (${option?.type})`;
    }

    return `${option?.label} ${option?.type ? `(${option?.type})` : ''}`;
};

// Option Matching and Value Utilities
export const matchSelectedOption = (
    value: DropDownOptionType | null,
    optionSet: (DropDownOptionType | GroupedOptionSet)[]
): DropDownOptionType | null => {
    const selectedValue = getOptionValue(value);
    if (value?.field_domain_type === CUSTOM_CONSTANT) {
        return {
            ...value,
            type: 'Custom Constant'
        };
    } else if (value?.name === 'Owner.Email') {
        // TODO: Owner.Email is a Hacked field, this needs to be removed once this Mapping is fixed on the backend
        return {
            ...value,
            label: 'Contact Owner',
            type: 'email'
        };
    }

    let matchedOption = null;

    optionSet.every((optionSet) => {
        const found = optionSet?.options
            ? optionSet?.options.find((opt) => getOptionValue(opt) === selectedValue)
            : getOptionValue(optionSet) === selectedValue ? optionSet : false;

        matchedOption = !!found ? found : null;
        return !found;
    });

    return matchedOption;
};

export const valueGetterMethods = {
	[SALESFORCE_CONSTANT]: ({ field_domain_type, name, value, picklistValue }: SalesforceField) =>
	    `${field_domain_type}.${name}.${picklistValue?.value ? picklistValue?.value : value}`,
	[CUSTOM_CONSTANT]: ({ field_domain_type, name, value }: DropDownOptionType) => `${field_domain_type}.${name}.${value}`,
	[SALESFORCE]: ({ field_domain_type, name }: DropDownOptionType) => `${field_domain_type}.${name}`,
	[BENEFIT_FLOW]: ({ field_domain_type, name }: DropDownOptionType) => `${field_domain_type}.${name}`,
};

export const getOptionValue = (option) => {
	const getter = option && valueGetterMethods[option.field_domain_type];
	return getter ? getter(option) : null;
};
