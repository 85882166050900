import { useAddNotification } from '@benefitflow/designsystem';
import { useCallback } from 'react';

export const useOnClickExportPDF = () => {
	const addNotification = useAddNotification();
	const onClickExportPDF = useCallback(() => {
		addNotification({ type: 'info', title: 'Exporting PDF:', message: 'This feature is not yet implemented.', autoClose: true });
	}, [addNotification]);

	return onClickExportPDF;
};
