import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useListDetailsFilterStore } from '../../../ListDetails.store';
import { getDefaultContactFilters, listsDetailPageActions } from 'app/containers/ListsPage/containers/ListsDetailPage/slice';

export const useOnClick = () => {
	const dispatch = useDispatch();
	const {
		collapsible: { clear },
	} = useListDetailsFilterStore();
	const onClick = useCallback(() => {
		dispatch(listsDetailPageActions.updateContactFilters(getDefaultContactFilters()));
		clear();
	}, [dispatch, listsDetailPageActions, getDefaultContactFilters, clear]);

	return onClick;
};
