import { create } from 'zustand';

export interface BrokerAccountsFilterStore {
	collapsible: {
		map: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>;
		toggle: Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>;
		clear: () => void;
	};
}

export const COLLAPSIBLE_MAP_KEYS = [
	'officeLocation',
	'primaryBroker',
	'employeeCount',
	'hqLocation',
	'industry',
	'employerType',
	'funding',
	'insuranceProducts',
	'carrier',
	'renewalDate',
] as const;

export const useBrokerAccountsFilterStore = create<BrokerAccountsFilterStore>((set) => ({
	collapsible: {
		map: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = false;
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
		),
		toggle: COLLAPSIBLE_MAP_KEYS.reduce(
			(acc, key) => {
				acc[key] = () =>
					set((state) => ({
						collapsible: {
							...state.collapsible,
							map: {
								...Object.keys(state.collapsible.map).reduce(
									(acc, cur) => ({ ...acc, [cur]: false }),
									{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
								),
								[key]: !state.collapsible.map[key],
							},
						},
					}));
				return acc;
			},
			{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], () => void>
		),
		clear: () =>
			set((state) => ({
				collapsible: {
					...state.collapsible,
					map: COLLAPSIBLE_MAP_KEYS.reduce(
						(acc, cur) => ({ ...acc, [cur]: false }),
						{} as Record<(typeof COLLAPSIBLE_MAP_KEYS)[number], boolean>
					),
				},
			})),
	},
}));
