import cn from 'classnames';
import { useState } from 'react';
import { PageContainer } from 'new-beginning/components/shared/PageContainer';
import { Tabs, Tab, TabPanel } from 'molecules/Tabs';
import { SalesforceHeader } from './SalesforceHeader';
import { SalesforceSettingContainer } from './SalesforceSettingContainer';
import { FieldMappingTable } from './field_mapping/FieldMappingTable';
import {
	useSalesforceFields,
	BFMappingTypes,
	SalesforceObjectTypes,
	CrmObjectType,
	GroupedOptionSet,
} from 'new-beginning/hooks/salesforce/useSalesforceConfigurationData';
import { useSalesforceFieldMappings } from 'new-beginning/hooks/useCrmMappingData';
import { BENEFIT_FLOW_CRM_OBJECTS } from 'common.model/src/types/integration/fieldMapping/BenefitFlowFields';
import { CrmIntegrationFieldDomainType } from 'common.model/src/types/integration/fieldMapping/FieldDomainType';

interface SalesforceSettingsProps {}

enum CrmObjectMappingLevel {
	PARENT_ACCOUNT = "Parent Account",
	CHILD_ACCOUNT = 'Child Account',
	CONTACT = 'Contact',
}

const mappingFieldTypes: [BFMappingTypes, SalesforceObjectTypes, CrmObjectMappingLevel][] = [
	[BFMappingTypes.BROKER, SalesforceObjectTypes.ACCOUNT, CrmObjectMappingLevel.PARENT_ACCOUNT],
	[BFMappingTypes.BROKER_OFFICE, SalesforceObjectTypes.ACCOUNT, CrmObjectMappingLevel.CHILD_ACCOUNT],
	[BFMappingTypes.BROKER_CONTACT, SalesforceObjectTypes.CONTACT, CrmObjectMappingLevel.CONTACT],
];

const customValueField = {
	label: 'Custom Value',
	field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
	options: [
		{
			name: 'userDefinedField',
			label: 'Custom Value',
			field_domain_type: CrmIntegrationFieldDomainType.CUSTOM_CONSTANT,
		},
	],
};

const decorateFieldOptions = (
	options: CrmObjectType[] = [],
	groupLabel: string,
	field_domain_type: CrmIntegrationFieldDomainType
): GroupedOptionSet[] => {
	return [{ label: groupLabel, options, field_domain_type }];
};

export const SalesforceSettings = ({}: SalesforceSettingsProps) => {
	const [activeTab, setActiveTab] = useState<number>(0);

	const [ benefitFlowFieldType, salesforceFieldType, mappingLevel ] = mappingFieldTypes[activeTab];
	const { fieldMappings, loading: loadingMappings, clearCache: reloadMappings } = useSalesforceFieldMappings(benefitFlowFieldType);
	const { salesforceFields, loading: loadingFields, clearCache: clearFields } = useSalesforceFields(salesforceFieldType);

	const isLoading = loadingFields || loadingMappings;

	return (
		<PageContainer header={<SalesforceHeader reSyncFields={clearFields} />}>
			<div className={cn('bg-white', 'rounded')}>
				<div className={cn('p-4', 'text-muted')}>
					<p className={cn('bf-txt', 'txt-neutral', 'txt-bolder', 'txt-subheader', 'pb-1')}>{mappingLevel} Field Mapping</p>
					<p className={cn('bf-txt', 'txt-md', 'txt-lighter')}>
						Configure Field Mappings to determine how BenefitFlow enriches <strong>{mappingLevel}</strong> Objects within your Salesforce.
					</p>
				</div>
				<Tabs value={activeTab} onChange={(e, idx: number) => setActiveTab(idx)}>
					<Tab label='Brokers' />
					<Tab label='Offices' />
					<Tab label='Contacts' />
				</Tabs>
				<TabPanel value={activeTab} index={0}>
					<SalesforceSettingContainer>
						<FieldMappingTable
							crmObjectType={BFMappingTypes.BROKER}
							fieldMappings={fieldMappings?.[BFMappingTypes.BROKER]}
							sourceFieldSet={[
								...decorateFieldOptions(
									BENEFIT_FLOW_CRM_OBJECTS.BROKER,
									'BenefitFlow Fields',
									CrmIntegrationFieldDomainType.BENEFIT_FLOW
								),
								...decorateFieldOptions(
									salesforceFields?.ACCOUNT?.picklist,
									'Saleforce Constants',
									CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT
								),
								customValueField,
							]}
							targetFieldSet={salesforceFields?.ACCOUNT?.fields}
							loading={isLoading}
							refreshMappingFields={reloadMappings}
						/>
					</SalesforceSettingContainer>
				</TabPanel>
				<TabPanel value={activeTab} index={1}>
					<SalesforceSettingContainer>
						<FieldMappingTable
							crmObjectType={BFMappingTypes.BROKER_OFFICE}
							fieldMappings={fieldMappings?.[BFMappingTypes.BROKER_OFFICE]}
							sourceFieldSet={[
								...decorateFieldOptions(
									BENEFIT_FLOW_CRM_OBJECTS.BROKER_OFFICE,
									'BenefitFlow Fields',
									CrmIntegrationFieldDomainType.BENEFIT_FLOW
								),
								...decorateFieldOptions(
									salesforceFields?.ACCOUNT?.picklist,
									'Saleforce Constants',
									CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT
								),
								customValueField,
							]}
							targetFieldSet={salesforceFields?.ACCOUNT?.fields}
							loading={isLoading}
							refreshMappingFields={reloadMappings}
						/>
					</SalesforceSettingContainer>
				</TabPanel>
				<TabPanel value={activeTab} index={2}>
					<SalesforceSettingContainer>
						<FieldMappingTable
							crmObjectType={BFMappingTypes.BROKER_CONTACT}
							fieldMappings={fieldMappings?.[BFMappingTypes.BROKER_CONTACT]}
							sourceFieldSet={[
								...decorateFieldOptions(
									BENEFIT_FLOW_CRM_OBJECTS.BROKER_CONTACT,
									'BenefitFlow Fields',
									CrmIntegrationFieldDomainType.BENEFIT_FLOW
								),
								...decorateFieldOptions(
									salesforceFields?.CONTACT?.picklist,
									'Saleforce Constants',
									CrmIntegrationFieldDomainType.SALESFORCE_CONSTANT
								),
								customValueField,
							]}
							targetFieldSet={salesforceFields?.CONTACT?.fields}
							loading={isLoading}
							refreshMappingFields={reloadMappings}
						/>
					</SalesforceSettingContainer>
				</TabPanel>
			</div>
		</PageContainer>
	);
};
