import React from 'react';

import { useAppointments } from './hooks';
import { Box, Tooltip, Typography } from '@benefitflow/designsystem';

export class Appointment {
	first_appointment_date: string;
	company_name: string;
}

class CarrierAppointmentsProps {
	appointments: Appointment[];
}

export default function CarrierAppointments(props: CarrierAppointmentsProps) {
	const appointments = useAppointments(props.appointments);

	return (
		<span>
			{appointments.length > 0 ? (
				<Tooltip
					behavior='hover'
					strategy='fixed'
					placement='bottom-start'
					backgroundColor='white'
					content={
						<Box overflow='y-auto' style={{ maxHeight: '20rem' }} p='md' display='flex' flexDirection='column' gap='sm'>
							<Typography.Text whiteSpace='nowrap' size='md' weight='medium'>
								Carrier Appointments
							</Typography.Text>

							{appointments.map(([name, since], index) => (
								<Box key={index} display='flex' gap='sm' justifyContent='between'>
									<Box style={{ maxWidth: '18rem' }}>
										<Typography.Text weight='regular' size='sm' ellipsis whiteSpace='nowrap'>
											{name}
										</Typography.Text>
									</Box>
									<Typography.Text weight='regular' size='sm' whiteSpace='nowrap' color='neutral-400'>
										{since}
									</Typography.Text>
								</Box>
							))}
						</Box>
					}
				>
					{(ref) => (
						<Box containerRef={ref} display='flex' gap='xxs' style={{ cursor: 'pointer' }}>
							<Typography.Text
								decoration='underline'
								decorationStyle='dotted'
								decorationColor='secondary-500'
								size='sm'
								whiteSpace='nowrap'
							>
								{props?.appointments?.length}
							</Typography.Text>
							<Typography.Text
								size='sm'
								whiteSpace='nowrap'
								decoration='underline'
								decorationStyle='dotted'
								decorationColor='secondary-500'
								weight='regular'
								color='neutral-500'
							>
								Carrier Appointments
							</Typography.Text>
						</Box>
					)}
				</Tooltip>
			) : null}
		</span>
	);
}
