import { useQuery } from '@tanstack/react-query';
import { QueryPath } from 'new-beginning/constants/path';
import { QueryKey } from 'new-beginning/constants/queryKey';
import { List, ListApiResponse } from '../../components/pages/List/lib/entities/list';
import { LoggedInUserContext, UserContextType } from 'new-beginning/context/LoggedInUser';
import { useContext, useMemo, useState } from 'react';
import { PaginationParams, SortKey, SortOrder } from 'common.model/src/types/Pagination';
import { SetSortParams } from 'new-beginning/hooks/salesforce/useListContacts';
import { WithAuthenticatedContext } from 'new-beginning/context/AuthenticatedContext';

export const useGetList = () => {
	const { httpClient } = useContext(WithAuthenticatedContext);
	const { authUser }: UserContextType = useContext(LoggedInUserContext);
	const [currentSorting, setCurrentSorting] = useState<PaginationParams>(undefined);

	const handleSorting = (args: SetSortParams) => {
		setCurrentSorting({ sortKey: args.sortKey, sortOrder: args.sortOrder, offset: 0, limit: 0 });
	};
	const { data: listData, isLoading } = useQuery({
		queryKey: [QueryKey.Lists, authUser?.tenantIdOverride ?? ''],
		queryFn: () => httpClient.post<{ res: { data: Array<ListApiResponse> } }>(QueryPath.List),
		select: ({ data }) => data.res.data.map((row) => new List(row)),
		refetchInterval: Infinity,
		refetchOnWindowFocus: true,
	});

	const sortedData = useMemo(() => {
		switch (currentSorting?.sortKey) {
			case SortKey.ListName:
			case SortKey.ListCreatedAt:
			case SortKey.ListCreatedBy:
			case SortKey.ListContacts:
				if (currentSorting.sortOrder === SortOrder.ASC) {
					return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? 1 : -1));
				}
				return listData?.sort((a, b) => (a[currentSorting.sortKey] < b[currentSorting.sortKey] ? -1 : 1));
			case SortKey.ListType:
				if (currentSorting.sortOrder === SortOrder.ASC) {
					return listData?.sort((a, b) => (a.getListType < b.getListType ? 1 : -1));
				}
				return listData?.sort((a, b) => (a.getListType < b.getListType ? -1 : 1));
			default:
				return listData;
		}
	}, [currentSorting, listData]);

	return { data: sortedData, isLoading, handleSorting, currentSorting };
};
