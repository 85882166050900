import { call, put, takeLatest, all, select, take, race, delay } from 'redux-saga/effects';
import { StartExportPollingPayload, StopExportPollingPayload, appActions } from './slice';
import AmplifyTokenManager from 'utils/AmplifyTokenManager';
import serviceUrl from 'utils/serviceUrl';
import { requestSecure } from 'utils/request';
import { AppState } from './types';
import { selectAppPage } from './selectors';
import { PayloadAction } from '@reduxjs/toolkit';
import { UserType, UserTypeUtils } from 'common.model/src/utils/UserTypeUtils';
import ExportStatus from 'common.model/src/types/data-export/ExportStatus';
import { assertNever } from '../utils/enumUtils';
import { addNotification } from '@benefitflow/designsystem';


export function* appSaga() {
  yield all([
    loggedInSaga(),
    logoutSaga(),
    updateTokenSaga(),
    watchExportPolling(),
    downloadExport(),
  ]);
}

function* loggedInSaga() {
  yield takeLatest(appActions.loggedIn.type, load);
}


function* logoutSaga() {
  yield takeLatest(appActions.logout.type, logout);
}

function* updateTokenSaga() {
  yield takeLatest(appActions.getUpdatedToken.type, updateToken);
}

function* logout() {
  yield AmplifyTokenManager.getInstance().logout();
  yield put(appActions.loggedOut()); 
}

export function* load() {
  const page: AppState = yield select(selectAppPage);
  
  let token = page?.decodedIdToken;
  let userType = UserTypeUtils.parseUserType(token?.['cognito:groups']); 
  window['Appcues']?.identify(
    token?.sub, // unique, required
    {
      // easy properties to setup
      role: userType,
      accountId: UserTypeUtils.getTenantId(token?.['cognito:groups']),
      firstName: token?.name?.split(' ')?.[0],
      email: token?.email

      // recommended (optional) properties
  
          // createdAt: 1566932390, // Unix timestamp of user signup date
          // purchasedAd: 1566932395, // Unix timestamp of account purchase date (leave null if empty)
          // planTier: "Standard", // Current user’s plan tier
          // role: "Admin", // Current user’s role or permissions
          // accountId: "1234", // Current user's account ID
          // firstName: "John", // current user's first name
  
      // additional suggestions
  
          // companyName: "Acme Corp", // Current user’s company name
          // email: "john.doe@example.com", // Current user's email
          // location: "90210", // a zipcode, state, or country enables location-based targeting
          // version: "2.0", // users on different versions may need to see different content
          // language: "spanish", // for multi-language applications
          // renewalDate: 1577880288 // to remind users to renew
    }
  );
  window["heap"].identify(token?.email);

  if (userType == UserType.BENEFIT_ADMIN) {
    yield loadAllTenants();
  }
}

export function* loadAllTenants() {
  const requestUrl = `${serviceUrl}/tenant/getAllTenants`;
  const reqOptions = {
    method: 'POST', 
  };
  try {
    const res: any = yield call(requestSecure, requestUrl, reqOptions); 
    const tenants = res.data;
    yield put(appActions.updateTenants(tenants));
    if (tenants && tenants.length > 0) {
      yield put(appActions.setSelectedLoginAsTenant(localStorage.getItem('defaultTenant') || tenants[0]?.tenant_id));
    }
  } catch (err) {
    // alert('Failed to load - Try refreshing the page.');
  }
}

export function* startExportPolling(action: PayloadAction<StartExportPollingPayload>) {
  console.log(`startExport polling invoked with ${action.payload.exportId}`);
  yield put(appActions.addLoadingBanner({ message: "Exporting...", id: action.payload.exportId}));
  while(true) {
    console.log('waiting to poll...');
    yield delay(5000);
    console.log('polling for export status for ', action.payload.exportId);
    const requestUrl = `${serviceUrl}/data-export/checkExportStatus`;
    const reqOptions = {
      body: JSON.stringify({ exportId: action.payload.exportId }),
    };
    try {
      const res = yield call(requestSecure, requestUrl, reqOptions);
      const { exportId, status: rawExportStatus } = res.data;
      const exportStatus = rawExportStatus as ExportStatus;
      console.log(`response from status is ${exportId} and ${exportStatus}`);
      switch(exportStatus) {
        case ExportStatus.Accepted:
        case ExportStatus.InProgress:
          // Repoll only
          console.log('repolling...');
          break;
        case ExportStatus.Complete:
        case ExportStatus.Downloaded:
          console.log('export complete!');
          yield put(appActions.addLoadingBanner({ message: "Export complete. Downloading...", id: exportId }));
          yield put(appActions.downloadExport(exportId));
          break;
        case ExportStatus.Failed:
        case ExportStatus.Unknown:
          console.log(`export failed with status ${exportStatus}`);
          yield put(appActions.stopExportPolling({ exportId }));
          break;
        default:
          assertNever(exportStatus);
          console.log(`unexpected export status ${exportStatus}`);
          yield put(appActions.stopExportPolling({ exportId }));
          break;
      }
    } catch (err) {
      console.log(`error while polling for export status ${err}`);
      yield put(appActions.stopExportPolling({ exportId: action.payload.exportId }));
      addNotification({
        type: 'error',
        message: 'Export failed.',
      });
    }
  }
}

export function* stopExportPolling(action: PayloadAction<StopExportPollingPayload>) {
  yield put(appActions.onCloseLoadingBanner(action.payload.exportId));
}

export function* watchExportPolling() {
  while(true) {
    const action = yield take(appActions.startExportPolling.type);
    const [ _, stopPayload] = yield race([call(startExportPolling, action), take(appActions.stopExportPolling.type)]);
    if (stopPayload) {
      yield call(stopExportPolling, stopPayload);
    }
  }
}

export function* downloadExport() {
  while(true) {
    const action: PayloadAction<string> = yield take(appActions.downloadExport.type);
    console.log(`downloading export with id ${action.payload}`);
    try {
      const requestUrl = `${serviceUrl}/data-export/downloadExport`;
      const reqOptions = {
        body: JSON.stringify({ exportId: action.payload }),
      };
      const downloadResponse = yield call(requestSecure, requestUrl, reqOptions);
      const s3Url = downloadResponse.data;
      console.log(`s3 url is ${s3Url}`);
      const link = document.createElement('a');
      link.href = s3Url;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      console.log(`finished downloading export with id ${action.payload}`);
    } catch (err) {
      console.log(`error while downloading export ${err}`);
    } finally {
      yield put(appActions.stopExportPolling({ exportId: action.payload }));
    }
  }
}

export function* updateToken() {
  const token = yield AmplifyTokenManager.getInstance().getIdToken();
  yield put(appActions.loggedIn(token));
  yield put(appActions.updateToken());
}
