import { Avatar, Box, Checkbox, Paper } from '@benefitflow/designsystem';

import { BrokerContactCardProps } from './BrokerContactCard.types';
import { VerifiedProducerBadge } from 'atoms/VerifiedProducerBadge/VerifiedProducerBadge';
import { Buttons, Descriptions } from 'new-beginning/components/shared/ContactCards/components';
import { useScreenSize } from 'new-beginning/hooks/useScreenSize';
import { useGetValueByScreenSizes } from 'new-beginning/hooks/useGetValueByScreenSizes';
import { Card } from 'new-beginning/components/shared/Card/Card';

export const BrokerContactCard = ({
	checked,
	toggle,
	imageSrc,
	isVerified,
	contactLinkedinUrl,
	location,
	name,
	title,
	email,
	phone,
	licenses,
	isPurchased,
	appointments,
	monthsAtCompany,
	monthsInRole,
	companyLinkedinUrl,
	companyName,
	loading,
	hasEmail,
	hasPhone,
	isPurchasable,
	variant = 'list',
	onBrokerClick,
	onGetContact,
	gettingContact,
}: BrokerContactCardProps) => {
	const size = useScreenSize();
	const getValueByScreenSizes = useGetValueByScreenSizes();

	return (
		<Paper>
			<Box
				testId="card-container"
				gap="xs"
				display="grid"
				width="full"
				style={{
					gridTemplateColumns: getValueByScreenSizes({
						sm: 'repeat(1,1fr)',
						md: 'repeat(2,1fr)',
						lg: 'repeat(13,1fr)',
					}),
				}}
				overflow="hidden"
			>
				<Box
					gridSpan={getValueByScreenSizes({ md: 2, lg: 5, sm: 1 })}
					display="flex"
					justifyContent="start"
					alignItems="center"
					flexDirection={size === 'sm' ? 'column' : 'row'}
					gap="md"
					overflow="hidden"
				>
					<Checkbox testId="contact-checkbox" loading={loading} checked={checked} onChange={toggle} />
					<Avatar loading={loading} src={imageSrc} fallbackIcon="user" variant="circle" />
					<Box overflow="hidden" display="flex" width="full" flexDirection="column" justifyContent="start" alignItems="start" gap="xxs">
						<Card.Title loading={loading} linkedinUrl={contactLinkedinUrl}>
							{name}
						</Card.Title>
						<Card.Label weight="regular" size="sm" loading={loading}>
							{title}
						</Card.Label>
						<Card.Label weight="regular" size="sm" color="neutral-500" loading={loading}>
							{location}
						</Card.Label>
						<VerifiedProducerBadge loading={loading} isVerified={isVerified} licenses={licenses} />
					</Box>
				</Box>

				<Box gridSpan={getValueByScreenSizes({ md: 1, lg: 3, sm: 1 })} display="flex" overflow="hidden" flexDirection="column" justifyContent="center" gap="xs">
					{variant === 'list' ? (
						<Card.Title onClick={onBrokerClick} linkedinUrl={companyLinkedinUrl}>
							{companyName}
						</Card.Title>
					) : (
						<Card.Label color="neutral-400" weight="medium" loading={loading}>
							Experience
						</Card.Label>
					)}
					<Descriptions.Appointments loading={loading} appointments={appointments} />
					<Descriptions.YearsInRole loading={loading} months={monthsInRole} />
					<Descriptions.YearsAtCompany loading={loading} months={monthsAtCompany} />
				</Box>
				<Box
					gridSpan={getValueByScreenSizes({ md: 1, lg: 3, sm: 1 })}
					overflow="hidden"
					display="flex"
					width="full"
					flexDirection="column"
					justifyContent="center"
					alignItems="start"
					gap="xs"
				>
					{(hasEmail || hasPhone) && (
						<Card.Label color="neutral-400" weight="medium" loading={loading}>
							Contact Info
						</Card.Label>
					)}
					<Descriptions.Email loading={loading} hasEmail={hasEmail} email={email} />
					<Descriptions.MobilePhones loading={loading} hasPhone={hasPhone} phone={phone} />
				</Box>
				<Box gridSpan={getValueByScreenSizes({ md: 2, lg: 2, sm: 1 })} display="flex" justifyContent={size === 'lg' ? 'end' : 'center'} alignItems="center">
					<Buttons.GetContacts
						loading={loading}
						gettingContact={gettingContact}
						purchaseContact={onGetContact}
						isPurchasable={isPurchasable}
						isPurchased={isPurchased}
					/>
				</Box>
			</Box>
		</Paper>
	);
};
