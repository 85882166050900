import React, { useEffect, useRef, useCallback } from 'react';
import { Table } from 'react-bootstrap';
import { makeStyles } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

import './style.scss';
import { FormattingUtils } from "utils/index";

const useStyles = makeStyles({
  scrollBar: {
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#C4C4C4',
      borderRadius: '2px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#555',
    },
  },
});

export interface EmployersTableProps {
  className?: any;
  employers?: any[];
  brokerId: string;
  onClickEmployer?: any;
  onClickTopBroker?: any;
  autoScrollRequired?: boolean;
  scrollToRow?: string;
}

const EmployersTable = ({
  className,
  employers,
  brokerId,
  onClickEmployer,
  onClickTopBroker,
  autoScrollRequired,
  scrollToRow,
}: EmployersTableProps) => {
  const classes = useStyles();

  const refScrollArea = useRef(null);

  const scrollToSelectedPlan = useCallback(async () => {
    if (scrollToRow) {
      const selectedRowEl = document.getElementById(
        `employer-table-row-${scrollToRow}`,
      );

      if (selectedRowEl && refScrollArea && refScrollArea.current) {
        refScrollArea.current.scrollTo({
          top: Math.max(selectedRowEl.offsetTop - 100, 0),
          behavior: 'smooth',
        });
      }
    }
  }, [scrollToRow]);

  useEffect(() => {
    if (autoScrollRequired && !!scrollToRow) {
      scrollToSelectedPlan();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoScrollRequired, scrollToRow]);

  function formatDate(renewal_date_str) {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const renewal_date = new Date(renewal_date_str);
    const month = renewal_date.getMonth();
    const day = renewal_date.getDate();
    const formattedDay =
      day === 1 || day === 31
        ? `${day}st`
        : day === 2
        ? `${day}nd`
        : day === 3
        ? `${day}rd`
        : `${day}th`;
    return `${monthNames[month]} ${formattedDay}`;
  }

  return (
    <div
      ref={refScrollArea}
      style={{ display: 'block', maxHeight: '50vh', overflowY: 'scroll', marginTop: '1rem' }}
      className={classes.scrollBar}
    >
      <Table striped className={`dev-table ${className}`}>
        <thead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
          <tr>
            <th>EMPLOYER NAME</th>
            <th>EMPLOYER LOCATION</th>
            <th>FUNDING</th>
            <th>PRIMARY BROKER</th>
            <th>RENEWAL DATE</th>
            <th>CARRIER PREMIUMS</th>
            <th>TOTAL EMPLOYEES</th>
          </tr>
        </thead>
        <tbody>
          {employers
            ? employers
              .slice()
              .sort((a, b) => (b.total_employees ?? 0) - (a.total_employees ?? 0 ))
              .map((i, index) => {
                return (
                  <tr
                    key={index}
                    id={`employer-table-row-${i.employer_master_id}`}
                    className={`employer-table-row ${
                      i.employer_master_id === scrollToRow ? 'active' : ''
                    }`}
                  >
                    <td
                      onClick={() => {
                        onClickEmployer(i.employer_master_id);
                      }}
                      className={'clickable'}
                    >
                      {FormattingUtils.formatUpperCasing(i.employer_master_name)}
                    </td>
                    <td>{
                      !i.employer_city && !i.employer_state ? 
                        `[Office Not Specified]` :
                        `${FormattingUtils.formatUpperCasing(i.employer_city,)}, ${i.employer_state}`
                    }</td>
                    <td style={{ whiteSpace: 'nowrap' }}>{i.funding_type}</td>
                    <td
                      className={
                        brokerId !== i.primary_broker_unique_code
                          ? 'clickable'
                          : null
                      }
                      onClick={() =>
                        onClickTopBroker(
                          i.primary_broker_unique_code,
                          i.primary_broker,
                        )
                      }
                    >
                      {FormattingUtils.formatUpperCasing(i.primary_broker)}
                    </td>
                    <td>{formatDate(i.renewal_date)}</td>
                    <td>
                      {FormattingUtils.formatNumberAbbrev(i.total_premiums)}
                    </td>
                    <td>
                      {FormattingUtils.formatNumberAbbrev(i.total_employees)}
                    </td>
                    {/* <td>
                      {FormattingUtils.formatNumberAbbrev(i.total_premiums)}
                    </td> */}
                  </tr>
                );
              })
            : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map(x => {
                return (
                  <tr key={x}>
                    <td className={'clickable'}>
                      <Skeleton variant="text" width={150} />
                    </td>
                    <td>
                      <Skeleton variant="text" width={150} />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    <td>
                      <Skeleton variant="text" />
                    </td>
                    {/* <td>
                      <Skeleton variant="text" />
                    </td> */}
                  </tr>
                );
              })}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployersTable;
