import React from 'react';
import { PrimarySpanMedium } from 'atoms/StyledComponents';
import { FormattingUtils } from "utils/index";
import { CrmObjectTypeEnum, CrmObjectTypeUtils } from 'common.model/src/types/integration/fieldMapping/CrmObjectTypeEnum';
import { Checkbox } from '@benefitflow/designsystem';

export interface CardsListSelectAllProps {
  isSelectAll: boolean;
  onSelectAll: () => void;
  objectType: CrmObjectTypeEnum;
  totalRows: number;
  totalSelected: number;
}

export interface CardsListProps {
  rows: any[];
  isInfiniteLoading: boolean;
  searchInfinite: () => any;
  getCard: (row) => any;
  getLoadingCard: () => any;
  buttons?: React.ReactNode[];
  selectAll?: CardsListSelectAllProps
}

export function CardsList(props: CardsListProps) {

  React.useEffect(() => {
    // Infinite Scroll
    const isBottom = el => {
      const scrollableHeight = el.scrollHeight;
      const scrolledHeight =
        document.documentElement.scrollTop + el.getBoundingClientRect().height;
      return scrolledHeight >= scrollableHeight * 0.95;
    };

    const trackScrolling = (e) => {
      e.preventDefault(); // Cancel the native event
      e.stopPropagation();// Don't bubble/capture the event any further
      const el = document.getElementsByTagName('body')[0];
      if (isBottom(el)) {
        const notEndOfPlanResults =
          !props.rows ||
          (props.rows.length !== 0 && props.rows.length % 25 === 0);
        if (!props.isInfiniteLoading && notEndOfPlanResults) {
          props.searchInfinite();
        }
      }
    };

    document.addEventListener('scroll', trackScrolling);

    return () => {
      document.removeEventListener('scroll', trackScrolling);
    };
  }, [props]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      {
        props.selectAll && (
          <div style={{ position: "absolute", left: 0, top: 10 }}>
            <div style={{ display: "inline-block", marginRight: '0.25rem' }}>
                <Checkbox 
                    checked={props.selectAll.isSelectAll}
                    onChange={props.selectAll.onSelectAll}
                />
            </div>
            <PrimarySpanMedium> {FormattingUtils.formatCommas(props.selectAll.totalSelected)} Selected</PrimarySpanMedium>
            <span className="cards-list-total-rows"> / {FormattingUtils.formatCommas(props.selectAll.totalRows)} {CrmObjectTypeUtils.getName(props.selectAll.objectType) + 's'}</span>
          </div>
        )
      }
      <div style={{ overflow: "auto", marginBottom: "15px" }}>
        <div style={{ float: "right" }}>
          {props.buttons?.map((button, index) => (
            <div key={index} style={{ display: "inline-block", marginRight: index != props.buttons.length-1 ? "10px" : 0 }}>{button}</div>
          ))}
        </div>
      </div>
      {' '}
      {props.rows
        ? props.rows.map((row, index) => (
            <div key={index} className="card-container" style={{ marginBottom: '12px' }}>
              {props.getCard(row)}
            </div>
          ))
        : null}
      {!props.rows || props.isInfiniteLoading
        ? new Array(!props.rows ? 25 : 1).fill(0).map((row, ix) => (
            <div key={ix} style={{ marginBottom: '12px' }}>
              {props.getLoadingCard()}
            </div>
          ))
        : null}
      <br />
    </div>
  );
}
