import { useCallback, useEffect, useState } from 'react';

const DEFAULT_LARGE_SCREEN = 1000;
const DEFAULT_SMALL_SCREEN = 600;

export const useScreenSize = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const [screenSize, setScreenSize] = useState<'sm' | 'md' | 'lg'>('lg');

	useEffect(() => {
		if (width >= DEFAULT_LARGE_SCREEN) {
			setScreenSize('lg');
		} else if (width <= DEFAULT_SMALL_SCREEN) {
			setScreenSize('sm');
		} else {
			setScreenSize('md');
		}
	}, [width, setScreenSize]);

	const handleResize = useCallback(() => {
		setWidth(window.innerWidth);
	}, [setWidth]);

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, [handleResize]);

	return screenSize;
};
